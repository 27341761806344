import React, { useState } from 'react'
import { Box } from '@mui/material'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import Previewer from './Previewer'

interface Props {
  file: string | File
  destroy: () => Promise<any>
  fileType?: string
  isOnboarding?: boolean
  isClientTemplate?: boolean
  isClient?: boolean
  isClientUser?: boolean
  createdOn?: string
  docGuid?: string
  clientGuid?: string
}

const ImagePreviewer: React.FC<Props> = ({
  file,
  destroy,
  fileType,
  isOnboarding,
  isClientTemplate,
  isClient,
  isClientUser,
  createdOn,
  docGuid,
  clientGuid,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file)

  return (
    <>
      <Previewer
        file={file}
        fileUrl={fileUrl}
        fileType={fileType}
        expanded={expanded}
        setExpanded={setExpanded}
        destroy={destroy}
        isOnboarding={isOnboarding}
        createdOn={createdOn}
        docGuid={docGuid}
        clientGuid={clientGuid}
        isClientTemplate={isClientTemplate}
        isClient={isClient}
        isClientUser={isClientUser}
      />
      {expanded && (
        <>
          <Box height="520px" overflow="auto">
            <Box
              style={{
                height: '100%',
              }}
            >
              <img src={fileUrl} />
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default ImagePreviewer
