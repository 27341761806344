import React from 'react'
import Button from '@mui/material/Button'
import { IClient } from '../../../service/interface'
import { Box, Typography } from '@mui/material'
import ConvertBillIcon from '../../../assets/images/icons/convert_bill_icon.png'

import { useHistory } from 'react-router-dom'

interface Props {
  client: IClient
  emailGuid: string
  prebillLocation: string
}

export const prebillSelected = 'prebill_selected'

const ConvertToBillBtn: React.FC<Props> = ({ client, emailGuid }) => {
  const history = useHistory()

  const handleOnClick = () => {
    window.localStorage.setItem(prebillSelected, emailGuid)
    history.push(`/clients/${client.guid}/convert-pre-bill/${emailGuid}`)
  }

  return (
    <Box>
      <Button onClick={handleOnClick}>
        <img alt="convert_bill" src={ConvertBillIcon} />
        <Typography sx={{ fontWeight: 350, textDecoration: 'underline' }}>
          Convert To Bill
        </Typography>
      </Button>
    </Box>
  )
}

export default ConvertToBillBtn
