import { IOrganization } from '../service/interface'

export const orgGuidProperty = 'orgGuid'

export const orgNameProperty = 'orgName'

export const loadOrgGuid = () => window.localStorage.getItem(orgGuidProperty) || ''

export const loadOrgName = () => window.localStorage.getItem(orgNameProperty) || ''

export const saveOrgGuid = (guid: string) => window.localStorage.setItem(orgGuidProperty, guid)

export const saveOrgName = (name: string) => window.localStorage.setItem(orgNameProperty, name)

export const saveOrgToStorage = (org: IOrganization) => {
  saveOrgGuid(org.guid)
  saveOrgName(org.name)
}
