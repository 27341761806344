import React, { useContext, useMemo, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { GridCellParams, GridColDef, GridEventListener, GridFilterModel } from '@mui/x-data-grid'

import DataTable from './client/DataTable'
import { useInboxEmails } from '../../service/hooks/email'
import { usePageSearch } from '../../utils/hooks'
import ArchiveBtn from '../../containers/org/Billing/ArchiveBtn'
import UnarchiveBtn from '../../containers/org/Billing/UnarchiveBtn'
import ConvertToBillBtn, { prebillSelected } from '../../containers/org/Billing/ConvertToBillBtn'
import { IClient, UserType } from '../../service/interface'
import { OrgGuidContext } from '../../contexts/OrgGuidContext'
import { getFormattedTime } from '../../utils/helpers'
import { CustomNoRowsOverlay } from './EmptyTableState'
import { ERole } from '../../service/enum'
import { dateFilterOperators, processBillFilter, stringFilterOperators } from '../../utils/filters'
import { useClientInboxEmails } from '../../service/hooks/clientEmail'

const usePrebillList = {
  client: useClientInboxEmails,
  bporg: useInboxEmails,
}

const baseColumns: GridColDef[] = [
  {
    field: 'received_on',
    headerName: 'Received Date',
    width: 200,
    filterOperators: dateFilterOperators,
  },
  {
    field: 'client_name',
    headerName: 'Client',
    width: 200,
    flex: 1.3,
    filterOperators: stringFilterOperators,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    width: 200,
    flex: 1.5,
    filterOperators: stringFilterOperators,
  },
  {
    field: 'sender',
    headerName: 'Submitted by',
    width: 200,
    filterOperators: stringFilterOperators,
  },
]

const archivedActions = {
  field: '',
  headerName: 'Actions',
  renderCell: (params: GridCellParams) => {
    const orgGuid = params.row.orgGuid as string
    const emailGuid = params.row.id as string
    return <UnarchiveBtn orgGuid={orgGuid} emailGuid={emailGuid} />
  },
  width: 300,
}

interface Props {
  clientGuid?: string
  userType: UserType
  clients?: string
  cardStyle?: React.CSSProperties
  archived?: boolean
  prevPage?: string
  formatStartDate?: string
  formatEndDate?: string
}

const PreBillsTable: React.FC<Props> = ({
  clientGuid,
  userType,
  clients,
  cardStyle,
  archived = false,
  prevPage,
  formatStartDate,
  formatEndDate,
}: Props) => {
  const [ordering, setOrdering] = useState<string>('')
  const [filterModel, setFilterModel] = useState<GridFilterModel>()
  const { page, setPage, search } = usePageSearch()
  const { orgGuid, orgUser } = useContext(OrgGuidContext)
  const selectedFilter = useMemo(() => {
    if (!filterModel) return undefined
    return processBillFilter(filterModel)
  }, [filterModel])

  const parameters = useMemo(() => {
    const initial = {
      page,
      clients,
      page_size: 10,
      archived: archived,
      search,
      ordering,
      received_after: formatStartDate !== '-' ? formatStartDate : undefined,
      received_before: formatEndDate !== '-' ? formatEndDate : undefined,
    }
    if (Array.isArray(selectedFilter)) {
      return {
        ...initial,
        [selectedFilter[0].field]: selectedFilter[0].value,
        [selectedFilter[1].field]: selectedFilter[1].value,
      }
    }
    if (selectedFilter) {
      return {
        ...initial,
        [selectedFilter.field]: selectedFilter?.value,
      }
    }
    return initial
  }, [page, clients, search, ordering, archived, formatStartDate, formatEndDate, selectedFilter])
  const guid = useMemo(() => {
    let userGuid = ''
    if (userType === 'client' && clientGuid) {
      userGuid = clientGuid
    } else {
      userGuid = orgGuid
    }
    return userGuid
  }, [userType, clientGuid, orgGuid])
  const { data, isLoading } = usePrebillList[userType](guid, parameters)

  window.onload = function () {
    window.localStorage.removeItem(prebillSelected)
  }

  const preBillActions = useMemo(
    () => ({
      field: '',
      headerName: 'Actions',
      renderCell: (params: GridCellParams) => {
        const client = params.row.client as IClient
        const orgGuid = params.row.orgGuid as string
        const emailGuid = params.row.id as string
        return (
          <Stack direction="row">
            <Box>
              <ConvertToBillBtn
                client={client}
                emailGuid={emailGuid}
                prebillLocation={prevPage || ''}
              />
            </Box>
            <Box>
              <ArchiveBtn orgGuid={orgGuid} emailGuid={emailGuid} />
            </Box>
          </Stack>
        )
      },
      width: 350,
    }),
    [prevPage]
  )

  const preBillClientStatus = useMemo(
    () => ({
      field: '',
      headerName: 'Status',
      renderCell: () => {
        return 'In process'
      },
      width: 350,
    }),
    []
  )

  const rows = data?.results.map((result) => ({
    id: result.guid,
    orgGuid,
    client: result.client,
    client_name: result.client.name,
    sender: `${result.sender_name} <${result.sender_email}>`,
    subject: result.subject,
    body: result.body,
    bodyType: result.body_content_type,
    received_on: getFormattedTime(result.received_on),
  }))

  const columns = useMemo(() => {
    if (orgUser?.role === ERole.observer) return baseColumns
    if (userType === 'client') return [...baseColumns, preBillClientStatus]
    return [...baseColumns, archived ? archivedActions : preBillActions]
  }, [archived, orgUser, preBillActions, userType, preBillClientStatus])

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    window.localStorage.setItem(prebillSelected, params.row.id)
  }

  return (
    <DataTable
      rows={rows || []}
      columns={columns}
      components={{
        NoRowsOverlay: () => CustomNoRowsOverlay('No bills to display.'),
      }}
      cardStyle={cardStyle}
      page={page}
      setPage={setPage}
      total={data?.count || 0}
      loading={isLoading}
      onRowClick={handleRowClick}
      getRowClassName={(params) => {
        const selectedRow = window.localStorage.getItem(prebillSelected)
        if (params.row.id === selectedRow) return 'prebill-selected'
        return ''
      }}
      clickable
      setSortOrder={setOrdering}
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
    />
  )
}

export default PreBillsTable
