import React, { useContext, useState } from 'react'
import { EBillAction } from '../service/enum'
import { queryClient } from '../App'
import { ClientBillQueryKeys, useClientBillActionApproveUpdate } from '../service/hooks/clientBill'
import { EToastType, ToastContext } from '../contexts/ToastContext'
import { Link, Typography } from '@mui/material'
import { clientRoutesPath } from '../routes/ClientRoutes'
import CheckIcon from '../assets/images/icons/check_icon_success.svg'

export const usePageSearch = () => {
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)

  return { search, page, setSearch, setPage }
}

export const useClientApproveBillAndNotify = () => {
  const useMutationResult = useClientBillActionApproveUpdate()
  const { mutateAsync: requestUpdateClientBillActionApprove } = useMutationResult
  const { showToast } = useContext(ToastContext)

  const approveBillAndNotify = async (clientGuid: string, billGuid: string, extBillId: string) => {
    try {
      await requestUpdateClientBillActionApprove({
        clientGuid,
        billGuid,
        action: EBillAction.approve,
      })

      showToast({
        type: EToastType.success,
        header: 'Bill approved!',
        message: (
          <Typography>
            You have approved{' '}
            <Link href={`${clientRoutesPath.bill}/${billGuid}`}>Invoice #{extBillId}</Link>
          </Typography>
        ),
        icon: CheckIcon,
      })

      await queryClient.invalidateQueries([ClientBillQueryKeys.GET_BILL_LIST])
    } catch {
      showToast({
        type: EToastType.error,
        header: 'Unable to approve',
        message: 'Please check the limit of this bill.',
      })
    }
  }

  return { approveBillAndNotify, ...useMutationResult }
}
