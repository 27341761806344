import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Box, Button, Dialog, DialogActions, DialogTitle, Tooltip } from '@mui/material'

import { EmailQueryKeys } from '../../service/hooks/email'
import { OrgBillQueryKeys } from '../../service/hooks/orgBill'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ButtonDanger } from '../../utils/styles'
import DeleteIcon from '@mui/icons-material/Delete'

interface Props {
  destroy: () => Promise<any>
  fileType?: string
  isOnboarding?: boolean
}

export const RemoveButton: React.FC<Props> = ({
  destroy,
  fileType = 'Attachment',
  isOnboarding,
}) => {
  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient()

  const handleClose = () => setOpen(false)

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete this {fileType}?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ButtonDanger
            onClick={() => {
              return destroy().then(() => {
                queryClient.invalidateQueries(EmailQueryKeys.GET_ORG_EMAIL_ATTACHMENTS)
                queryClient.invalidateQueries(OrgBillQueryKeys.GET_BILL)
                setOpen(false)
              })
            }}
          >
            Remove
          </ButtonDanger>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setOpen((prev) => !prev)}>
        {isOnboarding ? (
          <DeleteIcon sx={{ color: '#73839C' }} />
        ) : (
          <Tooltip title="Delete attachment" placement="top-start" arrow>
            <DeleteOutlineIcon sx={{ color: '#E65100' }} />
          </Tooltip>
        )}
      </Button>
    </Box>
  )
}
