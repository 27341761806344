import { IPaymentType } from '../components/BillForm/BillFormInputDetails'
import { ITreatedAccount } from '../components/BillForm/interface'
import { IBillStatus } from '../containers/client/AdvancedSearch/FilterSearch'
import { ReportType } from '../containers/org/ClientReports/AddReportModal'
import {
  EBillAction,
  EApprovalCriteria,
  EBillAuditTrailEventType,
  EInvitationStatus,
  ERole,
  EWorkflowState,
  EBodyType,
  EPaymentMethod,
  EPermission,
  E2FAMethod,
  ENotificationType,
  EFrequency,
  EReceiptMethod,
  EOnboardingRole,
} from './enum'

export interface IWorkflowText {
  acronym: string
  description: string
}

export interface IDesignatedEntity {
  entity: IGuidWithName
  approve_up_to: string
}

export interface IDesignee {
  client_user: {
    user: IUser
    role: ERole
  }
  approve_up_to: string
  excluded_vendors: IGuidWithName[]
  excluded_divisions: IGuidWithName[]
  excluded_reports: ReportType[]
  allow_read_over_limit: boolean
}

export interface IDesignatedVendor {
  vendor: {
    name: string
    guid: string
    entity: IGuidWithName
  }
  approve_up_to: string
}

export interface IDesigneeReportDetails {
  client_user: {
    user: IUser
    role: ERole
    welcome_email_last_sent_on: string
  }
  excluded_reports: string[]
  entity: IEntity
}

export type IListDesigneeReportResponse = IPaginationResponse<IDesigneeReportDetails>

export interface IHeaders {
  Accept: string
  Authorization?: string
  'Content-Type'?: string
  'X-Client-Timezone'?: string
}

export interface ILoginInput {
  email: string
  password: string
}

export type ILoginResponse = INormalLoginResponse | I2FALoginResponse

export interface INormalLoginResponse {
  refresh: string
  access: string
  staff: IStaff
  type: UserType
  mfa_enabled: boolean
  mfa_required: boolean
  password_expired: boolean
}

export interface I2FALoginResponse {
  ephemeral_token: string
  method: E2FAMethod
  other_methods: string[]
}

export interface I2FAConfirmationResponse extends INormalLoginResponse {
  backup_codes: string[]
}

export interface I2FAResendCodeRequest {
  method: string
  ephemeralToken: string
}

export interface IRefreshResponse {
  access: string
}

export interface IError {
  message: string
  response: any
}

export type IStaff = 'member' | 'admin' | null

export interface IDecodedToken {
  exp: number
  jti: string
  token_type: 'access' | 'refresh'
  user_id: number
  staff: IStaff
  type: UserType
  mfa_enabled: boolean
  mfa_required: boolean
  terms_accepted: boolean
  terms_acceptance_required: boolean
  password_expired: boolean
}

export interface IPrimaryContact {
  guid: string
  first_name: string
  last_name: string
  email: string
  avatar: string
  phone: string
  secondary_phone?: string
  has_set_password?: boolean
}

export interface IClient extends IGuidWithName {
  slug: string
  primary_contact: IPrimaryContact
  created_on: string
  status: string
  address_line_1: string
  address_line_2: string
  referral: string
  city: string
  state: string
  zip_code: string
  plumb_service: string[]
  plumb_email: string
  master_client_name: string
  project_name: string
  bill_to_name: string
  bp_ff_amount: number
  foa_ff_amount: number
  bill_format: string
  total_ff_amount: number
  kickoff_call: IKickOff
  is_onboarding_completed: boolean
  payment_confirmation: boolean
  is_share_file_required: boolean
  folder_type: string
  folder_name: string
  is_app_email_confirmed: boolean
  is_plumb_email_confirmed: boolean
  is_folder_confirmed: boolean
  is_accounting_confirmed: boolean
  fee_option: string
  fee_per_entity: number
  is_auto_pay_authorized: boolean
  is_5_auto_standard_increase: boolean
  share_file_notes: string
  from_onboarding: boolean
  catch_up_fee: number
}

export interface IAssignedClient extends IClient {
  role?: string
  entities?: {
    entity: IEntity
  }[]
  is_onboarding_completed: boolean
}

export interface IDesignatedRequest {
  clientGuid: string
  userGuid: string
}

export interface IDestroyDesignatedVendorRequest extends IDesignatedRequest {
  vendorGuid: string
}

export interface IDestroyDesignatedEntityRequest extends IDesignatedRequest {
  entityGuid: string
}

export interface ICreateDesignatedEntityRequest extends IDesignatedRequest {
  entity: string
  approve_up_to: number | null
  allow_read_over_limit: boolean
  excluded_reports: string[]
}

export interface IUpdateDesignatedEntityRequest extends ICreateDesignatedEntityRequest {
  entityGuid: string
}

export interface ICreateDesignatedVendorRequest extends IDesignatedRequest {
  vendor: string
  approve_up_to: number
}

export interface IUpdateDesignatedVendorRequest extends ICreateDesignatedVendorRequest {
  vendorGuid: string
}

export interface IClientUpdateRequest {
  orgGuid: string
  clientGuid: string
  name?: string
  primary_contact?: {
    first_name: string
    last_name: string
    email: string
    phone: string
    secondary_phone?: string
  }
  address_line_1?: string
  address_line_2?: string
  referral?: string
  city?: string
  state?: string
  zip_code?: string
  telephone?: string
  plumb_email?: string
  plumb_service?: string[]
  master_client_name?: string
  project_name?: string
  bill_to_name?: string
  bp_ff_amount?: number
  foa_ff_amount?: number
  total_ff_amount?: string
  bill_format?: string
  kickoff_call?: IKickOff
  is_plumb_email_confirmed?: boolean
  folder_name?: string
  folder_type?: string
  is_share_file_required?: boolean
  payment_confirmation?: boolean
  is_folder_confirmed?: boolean
  is_accounting_confirmed?: boolean
  is_onboarding_completed?: boolean
  fee_option?: string
  fee_per_entity?: number
  is_auto_pay_authorized?: boolean
  is_5_auto_standard_increase?: boolean
  share_file_notes?: string
  from_onboarding?: boolean
  catch_up_fee?: number
}

export interface IUpdateEntityRequest {
  name: string
  archived: boolean
  allowable_payment_threshold: string | null
  verbal_approval_required: boolean
  client_approval_required: boolean
  customer_sync: boolean
  minimum_account_balance: number
  address_line_1?: string
  address_line_2?: string
  city?: string
  state?: string
  zip_code?: string
  fed_id?: string
  state_ein?: string
  corporate_id?: string
  state_incorporated?: string
  filing_date?: string | null
  track_by_class: boolean
  is_quickbooks_required: boolean
}

export interface ICreateEntityRequest {
  name: string
  allowable_payment_threshold: string | null
  verbal_approval_required: boolean
  client_approval_required: boolean
  customer_sync: boolean
  minimum_account_balance: number
  // last_synced_on: '2024-07-04T00:41:42.093Z'
  // last_synced_balance_on: '2024-07-04T00:41:42.093Z'
  archived?: boolean
  address_line_1?: string
  address_line_2?: string
  city?: string
  state?: string
  zip_code?: string
  fed_id?: string
  state_ein?: string
  corporate_id?: string
  state_incorporated?: string
  filing_date?: string | null
  track_by_class: boolean
  is_quickbooks_required: boolean
}

export interface IOrganization extends IGuidWithName {
  slug: string
  client_mfa_required: boolean
  org_mfa_required: boolean
  client_mfa_methods: E2FAMethod[]
  org_mfa_methods: E2FAMethod[]
}

export interface IUpdateOrganizationRequest {
  orgGuid: string
  name?: string
  client_mfa_required?: boolean
  org_mfa_required?: boolean
}

export interface IUser {
  email: string
  email_confirmed: boolean
  phone: string
  phone_confirmed: boolean
  first_name: string
  last_name: string
  avatar: string
  guid: string
  mfa_enabled: boolean
  has_set_password: boolean
  approval_limit: number
  manager: IManager
  date_of_birth: string
  secondary_phone: string
  address: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  ssn: string
  company_name: string
  title: string
  service: string
  connection_type: string
}

export interface IBillEvent {
  guid: string
  type: EBillAuditTrailEventType
  user: IUser
  diff: any
  description: string
  timestamp: string
  auto_approved_on: string
}

export interface IBillMessage {
  guid: string
  bill: {
    guid: string
  }
  from_user: IUser
  read_on: string
  body: string
  created_on: string
  updated_on: string
  unread: boolean
}

export interface IBillNote {
  guid: string
  from_user: IUser
  body: string
  created_on: string
  updated_on: string
}

export interface IMaskUser {
  guid: string
  email: string
  first_name: string
  last_name: string
  organizations: string
  clients: string
}

export interface IUserRequest {
  guid?: string
  email?: string
  phone?: string
  first_name?: string
  last_name?: string
  avatar?: string
  old_password?: string
  password?: string
  approval_limit?: number
  manager_guid?: string | null
  date_of_birth?: string
  ssn?: string
  secondary_phone?: string
  company_name?: string
  title?: string
  service?: string
  address?: string
  address_line_2?: string
  city?: string
  state?: string
  zip_code?: string
  connection_type?: string
}

export interface IUserResponse {
  user?: {
    guid?: string
    email?: string
    phone?: string
    first_name?: string
    last_name?: string
    avatar?: string
    has_set_password: boolean
  }
  role?: string
  approval_limit?: string
}

export interface IOrgUser {
  is_user_associate: boolean
  role: ERole
  user: IUser
  welcome_email_last_sent_on: string
  groups?: string[]
  connection_type: string
  client_relation?: string
  client?: string
  is_primary_contact?: boolean
}

export interface IOrgUserClientUser {
  role: EOnboardingRole | ERole
  user: IUser
  client: IClient
  client_relation?: string
}

export interface IManager {
  email: string
  phone: string
  first_name: string
  last_name: string
  avatar: string
  guid: string
  approval_limit: number
}

export interface IGroup {
  group: IGuidWithName
  user: IUser
}
export interface IGroupRequest {
  orgGuid: string
  userGuid: string
  groupGuids: string[]
}

export interface IInvitation {
  email: string
  role: ERole
  guid: string
  expires_on: string
  last_sent_on: string
  status: EInvitationStatus
}

export interface IReport {
  guid: string
  file: string
  type: string
  status: string
  generated_on: string
}

export interface IOrgGroupGuid {
  orgGuid: string
  groupGuid: string
}

export interface IOrgGroupPermissionSlug extends IOrgGroupGuid {
  permissionSlug: string
}

export interface ICreateOrgGroupPermissionCreateRequest extends IOrgGroupGuid {
  permission: EPermission
}

export interface IBulkCreateOrgGroupPermissionCreateRequest extends IOrgGroupGuid {
  permissions: EPermission[]
}

export interface IOrgGroupUserGuid extends IOrgGroupGuid {
  userGuid: string
}

export interface IAddUserInGroupRequest extends IOrgGroupGuid {
  user: string
}

export interface ISlugWithName {
  slug: string
  name: string
}

export interface IGuidWithName {
  guid: string
  name: string
}

export interface IEntity extends IGuidWithName {
  allowable_payment_threshold: string
  verbal_approval_required: boolean
  client_approval_required: boolean
  archived: boolean
  minimum_account_balance: number
  customer_sync: boolean
  last_synced_on: string
  last_synced_balance_on: string
  client?: IClient
  fed_id: string
  state_ein: string
  corporate_id: string
  filing_date: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  track_by_class: boolean
  is_quickbooks_required: boolean
  is_auto_approved?: boolean
}

export interface IVendor extends IGuidWithName {
  approval_criteria: EApprovalCriteria
  addr1: string
  addr2: string
  addr3: string
  addr4: string
  addr5: string
  city: string
  state: string
  postal_code: string
  country: string
}

export interface IAccount extends IGuidWithName {
  parent?: string
}

export type BillAction = 'pending' | 'approved' | 'rejected' | 'not_needed'

export type UserType = 'bporg' | 'client'

export interface IWorkflow {
  state: EWorkflowState
  verbal_approval_required: boolean
  verbal_approval_reason: string
  client_approval: BillAction
  verbal_approval: BillAction
  org_approval: BillAction
  poa_approval: BillAction
  rejected_on: string
  reject_reason: RejectReason
  reject_other_description: string
  last_approval_on: string
  org_approval_on: string
  client_approval_on: string
}

export interface IBill {
  guid: string
  ext_bill_id: string
  client: IClient
  entity: IEntity
  vendor: IVendor
  payment_method: EPaymentMethod
  payment_term: IGuidWithName
  memo: string
  amount: number
  remaining_balance: number
  currency: string
  invoiced_on: string
  due_on: string
  created_from: IEmail | null
  workflow: IWorkflow
  attachments: { guid: string; file: string }[]
  can_be_approved: boolean
  can_be_updated: boolean
  paid_on?: string
  check_status?: 'C' | 'R' | ''
  line_items: Array<ILineItemResponse>
  minimum_account_balance: string
  customer_sync: boolean
  check_number: string
  account_balance: IAccountBalanceList
  qb_sync_status: boolean
  is_over_limit: boolean
  is_commitment_bill: boolean
  commitment: { guid: string; sequential_id: string }
}

export interface IExistingBill {
  guid: string
  ext_bill_id: string
  client: IClient
  entity: IEntity
  vendor: IVendor
  payment_method: EPaymentMethod
  memo: string
  amount: number
  remaining_balance: number
  currency: string
  invoiced_on: string
  due_on: string
  attachments: { guid: string; file: string }[]
  line_items: Array<ILineItemResponse>
  account_balance: IAccountBalanceList
  commitment?: { guid: string; sequential_id: string }
}

export interface IBillRequest {
  client: string
  entity: string
  vendor: string
  payment_term?: string
  payment_method: string
  memo: string
  currency: string
  invoiced_on: string
  due_on: string
  created_from?: string
  line_items: Array<ILineItem | ILineItemCommitment>
  account_balance_id: number
  is_prebill_auto_approved?: boolean
  is_commitment_bill?: boolean
  force_create?: boolean
  commitment_bill_id?: string
}

export interface IBillUpdateRequest {
  due_on: string
  ext_bill_id: string
  invoiced_on: string
  memo: string
  line_items: Array<ILineItem | ILineItemCommitment>
  account_balance_id: number
  is_commitment_bill: boolean
  force_update?: boolean
}

export interface IAccountAncestor {
  guid: string
  name: string
  type: string
  descendants?: IAccountAncestor[]
}

export interface IAccountDetails {
  guid: string
  name: string
  type: string
  descendants?: IAccountAncestor[]
}

export interface IDivision {
  guid: string
  name: string
}

export interface IBillOrgClientGuids {
  guid: string // client or org guid
  billGuid: string
}

export interface ICreateOrgEmailAttachmentRequest {
  orgGuid: string
  emailGuid: string
  file: any
}

export interface ICreateEmailAttachmentRequest {
  clientGuid: string
  emailGuid: string
  file: any
}

export interface IDeleteEmailAttachmentRequest {
  orgGuid: string
  emailGuid: string
  eaGuid: string
}

export interface ICreateBillMessageRequest extends IBillOrgClientGuids {
  body: string
}

export interface IBillMessageReadRequest extends IBillOrgClientGuids {
  messageGuid: string
}

export interface ICreateBillNoteRequest extends IBillOrgClientGuids {
  body: string
}

export interface ICreateBillAttachmentRequest extends IBillOrgClientGuids {
  file: any
}

export interface IDeleteBillAttachmentRequest extends IBillOrgClientGuids {
  baGuid: string
}

export interface IUpdateVendorRequest {
  orgGuid: string
  entityGuid: string
  vendorGuid: string
  approval_criteria: EApprovalCriteria
}

export interface IUpdateExcludedVendorRequest {
  clientGuid: string
  entityGuid: string
  userGuid: string
  vendorGuid: string
}

export interface IBulkExcludedVendorRequest {
  clientGuid: string
  entityGuid: string
  userGuid: string
  requests: {
    vendor: string
  }[]
}

export interface IBulkExcludedDivisionRequest {
  clientGuid: string
  entityGuid: string
  userGuid: string
  requests: {
    division: string
  }[]
}

export interface IUpdateExcludedClassRequest {
  clientGuid: string
  entityGuid: string
  userGuid: string
  divisionGuid: string
}

export interface IUpdateOrgUserRequest {
  role: ERole
  user: IUserRequest
}

export interface ICreateOrgUserRequest {
  orgGuid: string
  user: IUserRequest
  role: ERole
}

export interface IUpdateClientUserRequest {
  clientGuid: string
  userGuid: string
  role?: ERole | string
  user?: IUserRequest
  client_relation?: string
  client?: string
  should_update?: boolean
}

export interface ICreateOrgInvitationRequest {
  orgGuid: string
  invitation: {
    email: string
    role: ERole
  }
}

export const rejectReasonChoices = {
  paid_by_client: 'Bill Paid by Client',
  incorrect_amount: 'Incorrect Amount',
  not_provided: 'Service/Product not provided',
  other: 'Other',
}

export type RejectReason = keyof typeof rejectReasonChoices

export interface IClientBillActionRequest {
  clientGuid: string
  billGuid: string
  action: EBillAction
  reject_reason?: RejectReason
  reject_other_description?: string
}

export interface IBulkApproveBill {
  action: EBillAction
  bill: string
}

export interface IClientBillActionBulkApproveRequest {
  clientGuid: string
  requests: IBulkApproveBill[]
}

export interface IOrgBillActionRequest {
  orgGuid: string
  billGuid: string
  action: EBillAction
  client_approval_required: boolean
}

export interface IOrgReverseBillPayment {
  orgGuid: string
  billGuid: string
}

export interface ICreateClientInvitationRequest {
  clientGuid: string
  invitation: {
    email: string
    role: ERole
  }
}

export interface IUpdateOrgInvitationRequest {
  orgGuid: string
  invGuid: string
  invitation: {
    email: string
    role: ERole
  }
}

export interface IUpdateClientInvitationRequest {
  clientGuid: string
  invGuid: string
  invitation: {
    email: string
    role: ERole
  }
}

export interface IEmail {
  guid: string
  client: IClient
  sender_name: string
  sender_email: string
  subject: string
  body: string
  received_on: string
  body_content_type: EBodyType
  is_prebill_auto_approved: boolean
}

export interface IArchiveEmailRequest {
  orgGuid: string
  emailGuid: string
}

export interface IDeleteEmailRequest {
  orgGuid: string
  emailGuid: string
  attachmentGuid: string
}

export interface IUserInputSearch {
  client?: IClient | null
  entity?: IEntity | null
  vendors: IVendor[]
  accounts: ITreatedAccount[]
  divisions: IDivision[]
  paymentMethod: IPaymentType[]
  accountBalances: IAccountBalanceList[]
  minAmount: number | undefined
  maxAmount: number | undefined
  inputMinAmount: string
  inputMaxAmount: string
  selectedDateRange: IDateRange
  selectedPaidDateRange: IDateRange
  invoiceStartDate: string
  invoiceEndDate: string
  paidStartDate: string
  paidEndDate: string
  billStatus: IBillStatus[]
}

export interface IParReportSearch {
  client?: IClient | null
  entity?: IEntity[] | null
}

export interface IListBillRequest {
  page?: number
  page_size?: number
  clients?: string
  entities?: string
  client_approval_after?: string
  client_approval_before?: string
  org_approval_after?: string
  org_approval_before?: string
  invoiced_after?: string
  invoiced_before?: string
  payment_pending_after?: number
  payment_pending_before?: number
  vendors?: string
  verbal_approval_after?: string
  verbal_approval_before?: string
  workflow_state?: string
  client_approval_action?: string
  search?: string
  ordering?: string
  paid_after?: string
  paid_before?: string
  pay_from_accounts?: string
  payment_methods?: string
  accounts?: string
  divisions?: string
  amount_max?: number
  amount_min?: number
  frequency?: string
  needs_attention?: boolean
}
export interface IListBillAttachmentRequest {
  page?: number
  page_size?: number
  clients?: string
  entities?: string
  vendors?: string
  search?: string
  created_on_after?: string
  created_on_before?: string
  bills?: string
  bill__ext_bill_id?: string
  ordering?: string
  workflow_state?: string
}

export interface IListBillDownloadRequest extends IListBillRequest {
  export_type: 'pdf' | 'xlsx'
}

export interface IListInboxEmailRequest {
  clients?: string
  page?: number
  page_size?: number
  archived?: boolean
  search?: string
  received_after?: string
  received_before?: string
  ordering?: string
}

export interface IListInboxEmailSummary {
  pre_bill: number
}

export interface IListBillSummaryResponse {
  pre_bill: number
  client_approval: number
  verbal_approval: number
  org_approval: number
  poa_approval: number
  payment_pending: number
  paid: number
  rejected: number
  paid_total_amount: number
  client_approval_total_amount: number
  verbal_approval_total_amount: number
  org_approval_total_amount: number
  poa_approval_total_amount: number
  payment_pending_total_amount: number
}

export interface IBillsByPaidDateResponse {
  results: IBillsByPaidDateDataPoint[]
}

export interface IBillsByPaidDateDataPoint {
  date: string
  paid_count: number
}

export interface IBillMessageSummaryResponse {
  unread_count: number
}

export interface IPaginationResponse<T> {
  total: number
  count: number
  next: string
  previous: string
  results: T[]
}

export interface ICreateEmailIntegrationResponse {
  email_address: string
  connection_status: string
  last_synced_on: string
}

export interface IListOrgUserClients {
  orgGuid: string
  userGuid: string
  clientGuid?: string
  page?: number
  page_size?: number
  for_dashboard?: boolean
}

export interface IOrgUserClient {
  name: string
  primary_contact: IPrimaryContact
}

export interface IUserClientResponse extends IOrgUserClient {
  guid: string
  slug: string
}

export interface IAssignedOnboarding {
  guid: string
  role: string
  name: string
}

export interface ICreateOrgUserClientRequest {
  orgGuid: string
  name: string
  primary_contact?: IUserRequest
  is_onboarding_completed?: boolean
}

export interface IOrgUserClientRequest {
  clientGuid: string
  orgGuid: string
  userGuid: string
}

export interface IOrgUserClientAssignRequest extends IOrgUserClientRequest {
  role?: string
  client?: IOrgUserClient
}

export interface IListOrgUserClientsResponse {
  count: number
  next: string
  previous: string
  results: IUserClientResponse[]
}

export interface IQBORedirectResponse {
  redirect: string
}

export interface IQBOCompanyResponse {
  connected: boolean
  last_synced_on: string
  qb_realm_id: string
  name: string
}

export interface IQBDCompanyFileResponse {
  entity: IEntity
  company: string
  has_password: boolean
}

export interface IQBDCompanyFileUpdateRequest {
  entityGuid: string
  password: string
}

export interface IListUserRequest {
  page?: number
  disabled?: boolean
  search?: string
  page_size?: number
  is_accepted?: boolean
  ordering?: string
  group_name?: string
  manager?: string
  onboarding?: boolean
  is_onboarding_only?: boolean
  role?: string
  exclude_primary?: boolean
  exclude_blank_role?: boolean
}

export interface IListEntityRequest {
  page?: number
  page_size?: number
  search?: string
  archived?: boolean
  ordering?: string
  connection_status?: boolean
}

export interface IListVendorRequest {
  page: number
  page_size: number
  archived?: boolean
  search?: string
  ordering?: string
}

export type IListQBDCompanyFileResponse = IPaginationResponse<IQBDCompanyFileResponse>

export type IListClientsResponse = IPaginationResponse<IClient>

export type IListAssignedClientsResponse = IPaginationResponse<IAssignedClient>

export type IListOrganizationsResponse = IPaginationResponse<IOrganization>

export type IListOrgUsersResponse = IPaginationResponse<IOrgUser>

export type IListInvitationsResponse = IPaginationResponse<IInvitation>

export type IListMaskUsers = IPaginationResponse<IMaskUser>

export type IListBills = IPaginationResponse<IBill>

export type IListGuidWithName = IPaginationResponse<IGuidWithName>

export type IListEntityResponse = IPaginationResponse<IEntity>

export type IListVendors = IPaginationResponse<IVendor>

export type IListAccounts = IPaginationResponse<IAccountDetails>

export type IListInboxEmails = IPaginationResponse<IEmail>

export type IListClientUsersResponse = IPaginationResponse<IOrgUser>

export type IListOrgUserClientUsersResponse = IPaginationResponse<IOrgUserClientUser>

export type IListBillEventResponse = IPaginationResponse<IBillEvent>

export type IListBillMessageResponse = IPaginationResponse<IBillMessage>

export type IListBillNotesResponse = IPaginationResponse<IBillNote>

export type IListFileAttachments = IPaginationResponse<{ guid: string; file: string }>

export type IListDesignatedEntityResponse = IPaginationResponse<IDesignatedEntity>

export type IListDesigneeResponse = IPaginationResponse<IDesignee>

export type IListDesignatedVendorResponse = IPaginationResponse<IDesignatedVendor>

export type IListUserResponse = IPaginationResponse<IUser>

export type IListOrgGroupUserResponse = IPaginationResponse<{ group: IGuidWithName; user: IUser }>

export type IListGroupPermissionResponse = IPaginationResponse<{
  group: IGuidWithName
  permission: {
    slug: EPermission
    name: string
  }
}>

export type IListReportsResponse = IPaginationResponse<IReport>

export type IListUserOrgGroupPermission = IPaginationResponse<IUserGroup>

export type IListCommitments = IPaginationResponse<ICommitments>

export type IListClientEntityBillSummaryResponse = IPaginationResponse<IClientEntityBillSummaryResponse>

export interface IListReportsRequest {
  orgGuid?: string
  clientGuid: string
  year: string
  month?: string
  search?: string
  clients?: string
  page?: number
  page_size?: number
  entity?: string
  type?: string
}

export interface IReportsQuantities {
  year: number
  month?: number
  quantity: number
}

export type IReportsQuantitiesResponse = IPaginationResponse<IReportsQuantities>

export interface IReportsQuantitiesByMonthRequest {
  clientGuid: string
  orgGuid?: string
  year: number
  page?: number
  page_size?: number
}

export interface IReportsQuantitiesByYearRequest {
  clientGuid: string
  orgGuid?: string
  page?: number
  page_size?: number
}

export interface ILineItem {
  id?: string
  account: string
  division: string
  customer?: string
  amount: any
}

export interface ILineItemResponse {
  account: {
    guid: string
    name: string
    ordering: string
    ancestors: any
    type: string
  }
  division: IGuidWithName
  amount: number
  customer: IGuidWithName
}

export interface ILineItemCommitment {
  id?: string
  account: string
  division: string
}

export interface ICommitmentLineItemResponse {
  account: {
    guid: string
    name: string
    ordering: string
    ancestors: any
    type: string
  }
  division: IGuidWithName
}

export interface IToken {
  token: string
  status: string
}

export interface IReportSummaryResponse {
  check_registers_count: number
  cash_flow_count: number
  financial_statements_count: number
  custom_count: number
  printed_checks_count: number
  total_count: number
}

export interface IUserGroup extends IGuidWithName {
  permissions: ISlugWithName
}

export const dateFilterKeys = {
  client_approval_after: 'client_approval_after',
  client_approval_before: 'client_approval_before',
  invoiced_after: 'invoiced_after',
  invoiced_before: 'invoiced_before',
  org_approval_after: 'org_approval_after',
  org_approval_before: 'org_approval_before',
  paid_after: 'paid_after',
  paid_before: 'paid_before',
  payment_pending_after: 'payment_pending_after',
  payment_pending_before: 'payment_pending_before',
  poa_approval_after: 'poa_approval_after',
  poa_approval_before: 'poa_approval_before',
  rejected_after: 'rejected_after',
  rejected_before: 'rejected_before',
  verbal_approval_after: 'verbal_approval_after',
  verbal_approval_before: 'verbal_approval_before',
  last_approval_after: 'last_approval_after',
  last_approval_before: 'last_approval_before',
}

export type DateFilterKey = keyof typeof dateFilterKeys

export interface IDateFilterKey {
  startDate: DateFilterKey
  endDate: DateFilterKey
}

export interface IDateRange {
  startDate: Date | undefined
  endDate: Date | undefined
}

export interface IClientEntityBillSummaryRequest {
  clients?: string
  invoiced_after?: string
  invoiced_before?: string
  workflow_state: string
}

export interface IClientEntityBillSummaryResponse {
  client: string
  entities: {
    entity: string
    entity_total: number
  }[]
  client_total: number
}

export interface INotifications {
  bills: ENotificationType[]
  reports: ENotificationType[]
  entities_and_designees: ENotificationType[]
  messages: ENotificationType[]
  product_updates: ENotificationType[]
}

export interface ICommitments {
  guid: string
  sequential_id: string
  client: IClient
  entity: IEntity
  vendor: IVendor
  default_payment_method: EPaymentMethod
  payment_term: IGuidWithName
  frequency: EFrequency
  estimated_amount: number
  remaining_balance: number
  currency: string
  monthly_due_date?: number
  next_due_on: string
  repeat_annually: boolean
  repeat_bi_annually: boolean
  custom_due_dates: string[]
  creation_date?: number
  payment_status: string
  memo: string
  is_active: boolean
  is_hotlisted: boolean
  is_archived: boolean
  is_within_creation_date: boolean
  can_create_bill: boolean
  account_balance: IAccountBalanceList
  line_items: Array<ICommitmentLineItemResponse>
  receipt_method: EReceiptMethod
  receipt_other: string
}

export interface ICommitmentsRequest {
  page?: number
  page_size?: number
  clients?: string
  entities?: string
  vendors?: string
  search?: string
  ordering?: string
  is_active?: boolean
  is_archived?: boolean
  is_hotlisted?: boolean
  frequency?: string
  next_due_on_before?: string
  next_due_on_after?: string
  is_past_due?: boolean
  is_skipped?: boolean
  due_within_week?: boolean
}

export interface ICreateCommitmentRequest {
  client: string
  entity: string
  vendor: string
  default_payment_method: string
  estimated_amount: number
  frequency: string
  repeat_annually?: boolean
  custom_due_dates?: string[]
  creation_date?: string
  payment_status: string
  memo: string
  is_active?: boolean
  is_hotlisted?: boolean
  is_archived?: boolean
  account_balance_id: number
  line_items: Array<ILineItemCommitment>
  receipt_method: string
  receipt_other: string
}

export interface ICommitmentBill {
  id: string
  bill: IBill
  commitment: string
  skipped_due_date: string
  skipped_amount: number
  skip_reason: string
}

export type IListCommitmentBillsResponse = IPaginationResponse<ICommitmentBill>

export interface ICommitmentBillRequestPayload {
  bill?: string
  commitment?: string
  skipped_due_date?: string
  skipped_amount: number
  skip_reason?: string
}

export interface ICommitmentBillRequest {
  orgGuid: string
  id?: string
  commitmentGuid: string
  payload: ICommitmentBillRequestPayload
}

export interface IListCommitmentBillRequest {
  page?: number
  page_size?: number
  is_skipped?: boolean
  is_active?: boolean
}

export interface ICommitmentCashOutflowMonth {
  month: string
  amount: number
}

export type ICommitmentCashOutflow = ICommitmentCashOutflowMonth[]

export interface IClientMonthlyOverview {
  committed: number
  actual_paid: number
  actual_pending: number
}

export interface ICommitmentNote {
  from_user?: IUser
  body: string
  created_on: string
  note_type: string
}

export type IListCommitmentNotesResponse = IPaginationResponse<ICommitmentNote>

export interface ICommitmentNoteRequest {
  orgGuid: string
  commitmentGuid: string
  body: string
  note_type?: string
}

export interface IGenerateCommitmentsTemplatePayload {
  entity_guid: string
  client_guid: string
}

export interface IGenerateCommitmentsTemplateRequest {
  orgGuid: string
  payload: IGenerateCommitmentsTemplatePayload
}

export type IListCustomerResponse = IPaginationResponse<IGuidWithName>

export interface ICustomerRequest {
  page?: number
  page_size?: number
  search?: string
  archived: boolean
}
export interface IAccountBalance {
  id: number
  entity: IEntity
  account_name: string
  awaiting_approval: number
  approved_bills_total: number
  minimum_account_balance: number
  bank_account_balance: string
  account_type: string
  credit_limit: number
}

export type IListAccountBalance = IPaginationResponse<IAccountBalance>

export interface IUpdateEntityAccountRequest {
  orgGuid: string
  entityGuid: string
  accountBankId: number
  account_name: string
  account_type: string
  credit_limit: number | string
}

export interface IQBBillData {
  qb_id: string
  bill_guid: string
}

export type IQBListResponse = IPaginationResponse<IQBBillData>

export interface IAccountBalanceList {
  id: number
  account_name: string
  account_type: string
  account_sub_type: string
  bank_account_balance: string
}

export type IListAccountBalanceResponse = IPaginationResponse<IAccountBalanceList>

export interface IReminderHistory {
  id: number
  report: IReport
  report_reminder: number
  report_status: string
  skipped_reason: string
  skipped_reason_description: string
  reportId: string
  created_on: string
  reportDate: string
  due_on?: string
}

export interface IReminder {
  guid: string
  type: string
  report: string
  client: IClient
  entity: IEntity
  frequency: string
  monthly_due_date?: number
  next_due_on: string
  repeat_annually: boolean
  custom_due_dates: string[]
  memo: string
  is_archived: boolean
  status: string
  creation_date?: string
  can_create_report: boolean
  start_date: string | Date
  account_balance: IAccountBalanceList
  history_id?: number
  is_high_priority: boolean
}

export interface ICreateReminderRequest {
  guid?: string
  report: string
  type: string
  client: string
  entity: string
  frequency: string
  monthly_due_date?: number
  repeat_annually?: boolean
  repeat_quarterly?: boolean
  custom_due_dates?: string[]
  memo: string
  is_archived?: boolean
  creation_date?: string
  start_date?: string
  account_balance?: number
}

export interface IReminderRequest {
  clients?: string
  entities?: string
  reports?: string
  report_guid?: string
  is_archived?: boolean
  page?: number
  page_size?: number
  search?: string
  ordering?: string
  type?: string
}

export interface ICreateReminderHistoryRequest {
  report?: string
  report_reminder: string
  report_status: string
  skipped_reason: string
  skipped_reason_description: string
  due_on?: string
}

export type IListReportReminders = IPaginationResponse<IReminder>

export type IListReportReminderHistory = IPaginationResponse<IReminderHistory>

export const reportRejectReasonChoices = {
  no_bill_pay: 'No bill pay',
  client_does_not_want: 'Client does not want',
  other: 'Other',
}

export interface IClientReportRequest {
  client_users?: string
  entities?: string
  archived?: boolean
  clients?: string
}

export interface IClientInAppNotificationRequest {
  clientGuid: string
  entityGuid: string
  recipients: (string | undefined)[]
  isNotifyUrgent: boolean
  isFundingRequired: boolean
}

export interface IBalanceSnapshotRequest {
  page?: number
  page_size?: number
  search?: string
  entities?: string
  ordering?: string
  account_types?: string
  awaiting_approval?: string
}

export interface IBookBalanceSync {
  last_balance_sync: string
}

export interface IAuditHistorySearch {
  client?: IClient | null
  user?: IOrgUser | null
  actionType: string
  selectedDateRange: IDateRange
  startDate: string
  endDate: string
}

export interface IAuditHistoryRequest {
  page?: number
  page_size?: number
  ordering?: string
  search?: string
  client?: string
  user?: string
  action_type?: string
  audit_log_after?: string
  audit_log_before?: string
}

export interface ICreateAuditHistoryRequest {
  event_type: 'DataExported'
  client?: string
  meta_data?: any
}
export interface IAuditHistory extends IGuidWithName {
  actioned_by: IUser
  client: IClient
  user: IUser
  created_on: Date
  description: string
  event_type: string
}

export type IListAuditHistoryResponse = IPaginationResponse<IAuditHistory>

export interface IAttachment {
  guid: string
  file: string
}

export type IListBillAttachmentResponse = IPaginationResponse<IAttachment>

export interface IParSendToClientRequest {
  guid: string
  clientGuid: string
  payload: FormData
}

// Dashboard
export interface IUserDashboardChart {
  committed: number
  actual_paid: number
  actual_pending: number
  past_due: number
}

export interface IBillDashboardChart {
  waiting_approval_bills: number
  processed_bills: number
  approved_bills: number
  past_due_bills: number
}

export interface IAssociateCommitments {
  guid: string
  client: string
  entity: string
  vendor: string
  estimated_amount: number
  next_due_on: string
  can_create_bill: boolean
  commitment_bill_id: number
  skipped_due_date: string
  skip_reason: RejectReason
  skipped_amount: number
  sequential_id: string
  line_items: Array<ICommitmentLineItemResponse>
  default_payment_method: EPaymentMethod
  frequency: EFrequency
  payment_status: string
  is_hotlisted: boolean
}

export type IListAssociateCommitments = IPaginationResponse<IAssociateCommitments>

export interface IReportReminderNoteRequest {
  orgGuid: string
  reportGuid: string
  body: string
  note_type?: string
}

export interface IReminderNote {
  from_user?: IUser
  body: string
  created_on: string
  note_type: string
}

export type IListReminderNotesResponse = IPaginationResponse<IReminderNote>

export interface IReportReminderHistoryRequest {
  orgGuid: string
  reportGuid: string
  historyId: string
  payload: ICreateReminderHistoryRequest
}

export interface IValueTitle {
  value: string
  title: string
}

export interface ITaskList extends IGuidWithName {
  order: number
  duration: string
  link: string
  required: boolean
  client_visible: boolean
  disabled: boolean
}

export interface ITaskAutomationList extends IGuidWithName {
  status: string
  task_enable: IGuidWithName
}

// ONBOARDING
export interface ITaskListRequest {
  page?: number
  page_size?: number
  status?: string
  is_client_visible?: boolean
  is_hidden?: boolean
  is_required?: boolean
  ordering?: string
  search?: string
  task?: string
  task_id?: string
  is_client_driven?: boolean
  owner?: string
}

export interface ITask {
  guid: string
  id: number
  workflow: string
  name: string
  is_required: boolean
  is_client_visible: boolean
  is_disabled_at_start: boolean
  duration: number
  action_type: string
  action_link: string
  order: number
  owner: string
}

export interface ITaskCreateRequest {
  workflow?: string
  name: string
  is_required?: boolean
  is_client_visible?: boolean
  is_disabled_at_start?: boolean
  duration?: number
  action_type?: string
  action_link?: string
  owner?: string
  order?: number
}

export type IListTasksResponse = IPaginationResponse<ITask>

export interface ITaskItems {
  guid: string
  task: ITask
  client: IClient
  is_required: boolean
  is_client_visible: boolean
  is_client_driven: boolean
  is_hidden: boolean
  status: string
  created_on: string | null
  started_on: string | null
  updated_on: string
  completed_on: string | null
  completed_by: IUser | null
  owner: string
}

export type IListTaskItems = IPaginationResponse<ITaskItems>

export interface ICreateOnboardingWeeklyReport {
  clientGuid?: string
  body: string
}

export interface ITaskItemsSummaryResponse {
  actionable: number
  completed: number
  waiting: number
  checklist_progress: number
}

export interface ITaskItemRequest {
  is_client_visible?: boolean
  is_hidden?: boolean
  status: string
  started_on: string | null
  completed_on: string | null
  completed_by: string | undefined
}

export interface IValueTitle {
  value: string
  title: string
}

export interface IKickOff {
  qboFiles: boolean
  viewOnlyBankAccounts: boolean
  accessCreditCards: boolean
  accessOnlinePayments: boolean
  amexAccountManager: boolean
  poaBillPay: boolean
  handlePayroll: boolean
}

export interface IOnboardingTeam {
  id?: string
  person: string
  role: string
}

export interface ITaskList extends IGuidWithName {
  order: number
  duration: string
  link: string
  required: boolean
  client_visible: boolean
  disabled: boolean
}

export interface ITaskAutomationList extends IGuidWithName {
  status: string
  task_enable: IGuidWithName
}

export interface ITaskRule {
  guid: string
  task: ITask
  next_task: ITask
  action_type: string
}

export type IListTaskRuleResponse = IPaginationResponse<ITaskRule>

export interface ITaskRuleCreateRequest {
  task: string
  next_task: string
  action_type?: string
}

export interface IAppUserClientRequest {
  first_name: string
  last_name: string
  date_of_birth: Date | null
  ssn?: string
  client_relation?: string
  email: string
  phone: string
  secondary_phone?: string
  app_role: string
  service?: string
  address?: string
  address_line_2?: string
  city?: string
  state?: string
  zip_code?: string
  company_name?: string
  title?: string
  user_type?: string
}

export interface IAppUserProfessionalRequest {
  company_name: string
  first_name: string
  last_name: string
  title: string
  service: string
  email: string
  phone: string
  secondary_phone?: string
  address: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  app_role: string
}

export interface IOutlookEmailRequest {
  email_address?: string
  is_app_email_confirmed?: boolean
  is_plumb_email_confirmed?: boolean
  should_verify?: boolean
}

export interface IClientContact {
  guid: string
  first_name: string
  last_name: string
  date_of_birth: string
  client_relation: string
  client: string
  email: string
  company: string
}

export type IListClientContactResponse = IPaginationResponse<IClientContact>

export interface IClientContactRequest {
  page?: number
  page_size?: number
  search?: string
}

export interface IClientContactCreate {
  first_name: string
  last_name: string
  date_of_birth?: string
  client_relation: string
  client: string
  email?: string
  company?: string
}

export interface IEntityRequest extends IGuidWithName {
  tab_idx: number
  allowable_payment_threshold: string
  verbal_approval_required: boolean
  client_approval_required: boolean
  minimum_account_balance: string
  archived: boolean
  customer_sync: boolean
  last_synced_on: string
  last_synced_balance_on: string
  client?: IClient
  fed_id: string
  state_ein: string
  corp_id: string
  filing_date: Date | null
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  track_by_class: boolean
  is_quickbooks_required: boolean
  is_auto_approved?: boolean
}

// PROPERTIES
export interface IProperty {
  guid: string
  property_type: string
  address: string
  address_line_2: string
  city: string
  state: string
  country: string
  zip_code: string
  is_qbo_class: boolean
  client: string
  nickname: string
}

export type IListPropertyResponse = IPaginationResponse<IProperty>

export interface IPropertyListRequest {
  page?: number
  page_size?: number
  search?: string
}

export interface IPropertyCreateRequest {
  property_type: string
  address: string
  address_line_2?: string
  city: string
  state: string
  country: string
  is_qbo_class: boolean
  client: string
  zip_code?: string
  nickname?: string
}

export interface IDocsRequest {
  page?: number
  page_size?: number
  name?: string
  file?: any
  client?: string
  is_created_by_client?: boolean
}

export interface IOnboardingDocs extends IGuidWithName {
  file: string
  client: string
  created_on: string
  is_created_by_client: boolean
}

export type IListOnboardingDocsResponse = IPaginationResponse<IOnboardingDocs>

export interface IOnboardingReminder {
  entity: IEntity | null
  tab_idx: number
  type: string
  report: string
  frequency: string
  start_date: Date | null
  memo: string
  guid: string
  account_balance: IAccountBalanceList | null
  is_archived?: boolean
  is_high_priority?: boolean
  custom_due_dates?: string[]
  repeat_annually?: boolean
  monthly_due_date?: number
}

export interface IBankContacts {
  name: string
  phone: string
  email: string
}

export interface IClientBankContact {
  guid: string
  bank_name: string
  fax_no: string
  email: string
  address: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  bank_contacts: IBankContacts[]
}

export type IListClientBankContactResponse = IPaginationResponse<IClientBankContact>

export interface IClientBankContactCreate {
  bank_name: string
  fax_no?: string
  email?: string
  address: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  bank_contacts: IBankContacts[]
}
