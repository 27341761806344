import React, { useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
  Box,
  styled,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import SidebarHeader from '../SidebarHeader'
import HeaderUserbox from '../OrgHeaderUserbox'
import {
  box_minimized_style,
  box_maximized_style,
  menu_style,
  org_pages,
  org_redirect,
} from '../../layout-components/constants'
import { OrgGuidContext } from '../../../contexts/OrgGuidContext'
import { StyledAppBar } from '../../../utils/styles'

export const StyledLabelText = styled(Typography)(() => ({
  marginTop: 20,
  marginBottom: 20,
  marginLeft: 16,
  fontWeight: 350,
}))

export const StyledItemText = styled(Typography)(() => ({
  marginTop: 8,
  marginBottom: 8,
  marginLeft: 8,
  marginRight: 8,
  fontSize: 16,
  color: 'black',
}))

const OrgSidebarMenu: React.FC = () => {
  const { isAssociate, isManagerLead } = useContext(OrgGuidContext)

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const allPages = useMemo(() => {
    if (isAssociate || isManagerLead) {
      return ['Dashboard', ...org_pages]
    }
    return org_pages
  }, [isAssociate, isManagerLead])

  const allRedirects = useMemo(() => {
    if (isAssociate) {
      return ['/associate-dashboard', ...org_redirect]
    } else if (isManagerLead) {
      return ['/manager-dashboard', ...org_redirect]
    }
    return org_redirect
  }, [isAssociate, isManagerLead])

  return (
    <>
      <StyledAppBar>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box flex={1} sx={box_minimized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_minimized_style}>
              <Box>
                <IconButton
                  size="large"
                  aria-label="menu-bar"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  className="icon-button"
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={menu_style}
              >
                {allPages.map((page, index) => (
                  <MenuItem key={page}>
                    <NavLink className="nav-link-simple" to={allRedirects[index]}>
                      <StyledItemText>{page}</StyledItemText>
                    </NavLink>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={box_maximized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_maximized_style}>
              {allPages.map((page, index) => (
                <NavLink className="nav-link-simple" to={allRedirects[index]} key={page}>
                  <StyledLabelText>{page}</StyledLabelText>
                </NavLink>
              ))}
            </Box>
            <Box sx={box_maximized_style}>
              <HeaderUserbox />
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
    </>
  )
}

export default OrgSidebarMenu
