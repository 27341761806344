import React, { ElementType, useMemo } from 'react'
import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import { RemoveButton } from './RemoveButton'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import ImgIcon from '../../assets/images/icons/img_icon.png'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { fileNameFromUrl, parseWithoutTZ } from '../../utils/helpers'
import BlackDownloadIcon from '../../assets/images/icons/black_download_icon.png'
import { format } from 'date-fns'
import DeleteDoc from './DeleteDoc'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/report_download_icon.svg'

interface Props {
  file: string | File
  fileUrl: string
  fileType?: string
  expanded: boolean
  createdOn?: string
  attachmentIcon?: ElementType<any>
  destroy: () => Promise<any>
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  isOnboarding?: boolean
  isClientTemplate?: boolean
  isClient?: boolean
  isClientUser?: boolean
  docGuid?: string
  clientGuid?: string
}

const StyledFilename = styled(Chip)(() => ({
  backgroundColor: 'transparent',
  fontWeight: 300,
  '& .MuiChip-icon ': {
    marginLeft: 0,
    color: '#000000',
  },
  '& .MuiChip-label': { overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip' },
}))

const Previewer: React.FC<Props> = ({
  file,
  fileUrl,
  fileType,
  expanded,
  attachmentIcon,
  createdOn,
  isOnboarding,
  isClientTemplate,
  isClient,
  isClientUser,
  docGuid,
  clientGuid,
  destroy,
  setExpanded,
}: Props) => {
  const createdDate = useMemo(() => {
    if (createdOn) {
      const parseDate = parseWithoutTZ(createdOn)
      if (isClient) {
        return format(parseDate, 'MMM dd, yyyy')
      } else {
        return `${format(parseDate, 'MMM dd, yyyy HH:mm:ss')} PT`
      }
    }
    return ''
  }, [createdOn, isClient])
  const fileName = useMemo(() => (typeof file === 'string' ? fileNameFromUrl(file) : file.name), [
    file,
  ])
  const fileNameGridSize = useMemo(() => {
    let gridSize = 5
    if (!isOnboarding && !isClient) {
      gridSize = 9
    } else if (isClient) {
      gridSize = 8
    }
    return gridSize
  }, [isOnboarding, isClient])
  const ableToDelete = useMemo(() => {
    let showDelete = false
    if (!isClient) {
      showDelete = true
    } else if (isClientUser && isClient) {
      showDelete = true
    }
    return showDelete
  }, [isClient, isClientUser])

  const handleDownload = () => {
    if (fileName && typeof file === 'string') {
      axios
        .get(file, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, fileName)
        })
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={fileNameGridSize} mt={1}>
          <StyledFilename
            icon={
              attachmentIcon ? (
                <SvgIcon component={attachmentIcon} />
              ) : (
                <img alt="img-icon" src={ImgIcon} style={{ width: '25px', height: '25px' }} />
              )
            }
            label={fileName}
          />
        </Grid>
        {isOnboarding && (
          <Grid item xs={isClient ? 3 : 6}>
            <Box mt={2}>
              <Typography fontSize={12} color="#203353">
                {createdDate}
              </Typography>
            </Box>
          </Grid>
        )}
        {isOnboarding && typeof file === 'string' && !isClientTemplate ? (
          <>
            <Grid item xs={1}>
              <Stack direction="row">
                <Button sx={{ marginRight: 1 }} onClick={handleDownload}>
                  <img alt="line border" src={BlackDownloadIcon} />
                </Button>
                {ableToDelete && (
                  <DeleteDoc clientGuid={clientGuid || ''} docGuid={docGuid || ''} />
                )}
              </Stack>
            </Grid>
          </>
        ) : (
          <>
            {typeof file === 'string' && isClientTemplate ? (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={1}>
                  <Button onClick={handleDownload}>
                    <SvgIcon component={DownloadIcon} />
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={1}>
                  <Button>
                    <Tooltip title="Open attachment" placement="top-start" arrow>
                      <Link href={fileUrl} target="_blank" sx={{ color: '#757575' }}>
                        <OpenInNewIcon sx={{ width: 20, height: 20 }} />
                      </Link>
                    </Tooltip>
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <RemoveButton destroy={destroy} fileType={fileType} />
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={() => setExpanded((prev) => !prev)}>
                    {expanded ? (
                      <Tooltip title="Collapse attachment" placement="top-start" arrow>
                        <ExpandLessIcon sx={{ width: 20, height: 20, color: '#757575' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Expand attachment" placement="top-start" arrow>
                        <ExpandMoreIcon sx={{ width: 20, height: 20, color: '#757575' }} />
                      </Tooltip>
                    )}
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

export default Previewer
