export const box_minimized_style = {
  display: { xs: 'flex', md: 'none' },
}

export const box_maximized_style = {
  display: { xs: 'none', md: 'flex' },
}

export const menu_style = {
  display: { xs: 'block', md: 'none' },
}

export const org_pages = ['Bills', 'Commitments', 'Clients', 'Reports']
export const org_redirect = ['/billing', '/commitments', '/clients', '/reports']
export const client_pages = ['Dashboard', 'Commitments', 'Bills', 'Reports']
