import { useQuery } from 'react-query'
import { listClientInboxEmails } from '../api'
import { IListInboxEmailRequest } from '../interface'

export const EmailQueryKeys = {
  GET_CLIENT_INBOX_EMAILS: 'GET_CLIENT_INBOX_EMAILS',
}

export const useClientInboxEmails = (clientGuid: string, payload: IListInboxEmailRequest) =>
  useQuery(
    [EmailQueryKeys.GET_CLIENT_INBOX_EMAILS, clientGuid, payload],
    () => listClientInboxEmails(clientGuid, payload),
    { enabled: payload.clients !== 'all-clients' }
  )
