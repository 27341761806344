import React, { ReactNode } from 'react'
import cogoToast from 'cogo-toast'
import './toast.style.css'

interface IState {
  showToast: (props: ToastProps) => void
}

export enum EToastType {
  success = 'success',
  error = 'error',
  warn = 'warn',
  info = 'info',
  loading = 'loading',
}

export interface ToastProps {
  message: string | ReactNode
  type: EToastType
  header?: string
  icon?: string
}

interface IProps {
  children?: React.ReactNode | React.ReactNode[]
}

const initialState: IState = {
  showToast: () => null,
}

export const ToastContext = React.createContext<IState>(initialState)

export const ToastContextProvider = ({ children }: IProps) => {
  const showToast = ({ message, type, header = '', icon }: ToastProps) => {
    if (icon && header) {
      const renderIcon = () => {
        return <img src={icon} style={{ width: '30px', height: '30px' }} />
      }
      cogoToast[type](message, {
        heading: header,
        position: 'top-right',
        bar: { color: '#FFFFFF' },
        renderIcon: renderIcon,
      })
    } else {
      cogoToast[type](message, {
        position: 'top-right',
        heading: header,
        bar: { color: '#FFFFFF' },
      })
    }
  }

  return <ToastContext.Provider value={{ showToast }}>{children}</ToastContext.Provider>
}
