import * as React from 'react'
import Box from '@mui/material/Box'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { GridFilterInputValueProps, useGridRootProps } from '@mui/x-data-grid'
import SyncIcon from '@mui/icons-material/Sync'

export function InputNumberInterval(props: GridFilterInputValueProps) {
  const rootProps = useGridRootProps()
  const { item, applyValue, focusElementRef = null } = props

  const filterTimeout = React.useRef<any>()
  const [filterValueState, setFilterValueState] = React.useState<[string, string]>(item.value ?? '')
  const [applying, setIsApplying] = React.useState(false)

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current)
    }
  }, [])

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined]
    setFilterValueState(itemValue)
  }, [item.value])

  const updateFilterValue = (lowerBound: string, upperBound: string) => {
    clearTimeout(filterTimeout.current)
    setFilterValueState([lowerBound, upperBound])

    setIsApplying(true)
    filterTimeout.current = setTimeout(
      () => {
        setIsApplying(false)
        applyValue({ ...item, value: [lowerBound, upperBound] })
      },
      rootProps.filterMode === 'server' ? 1500 : 500
    )
  }

  const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
    const newUpperBound = event.target.value
    updateFilterValue(filterValueState[0], newUpperBound)
  }
  const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
    const newLowerBound = event.target.value
    updateFilterValue(newLowerBound, filterValueState[1])
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        pl: '20px',
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="From"
        label="From"
        variant="standard"
        value={Number(filterValueState[0]) || ''}
        onChange={handleLowerFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="To"
        label="To"
        variant="standard"
        value={Number(filterValueState[1]) || ''}
        onChange={handleUpperFilterChange}
        type="number"
        InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
      />
    </Box>
  )
}
