import React, { useState } from 'react'
import PhoneNumberStep from './PhoneNumberStep'
import { TwoFASetupStepProps } from './interface'
import VerifyCodeStep from './VerifyCodeStep'

const steps: React.FC<TwoFASetupStepProps>[] = [PhoneNumberStep, VerifyCodeStep]

interface Props {
  open: boolean
  onClose: () => void
}

const TwoFASetup: React.FC<Props> = ({ open, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [phone, setPhone] = useState('')

  const handleBack = () => {
    if (currentStep === 0) {
      onClose()
    } else {
      setCurrentStep((prev) => prev - 1)
    }
  }

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1)
    } else {
      setCurrentStep(0)
      onClose()
    }
  }

  const CurrentStepComponent = steps[currentStep]

  return (
    <CurrentStepComponent
      open={open}
      onClose={onClose}
      onBack={handleBack}
      onNext={handleNext}
      phone={phone}
      setPhone={setPhone}
    />
  )
}

export default TwoFASetup
