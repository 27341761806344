import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import LoadingIndicator from '../components/loading/LoadingIndicator'
import { orgGuidRequiredRoutes, orgRoutesPath } from '../routes/OrgRoutes'
import { useOrganizations } from '../service/hooks/organization'
import { loadOrgGuid, loadOrgName, saveOrgToStorage } from '../utils/organization'
import { useOrgUser } from '../service/hooks/orgUser'
import { IOrgUser } from '../service/interface'
import { useListUserOrgGroups } from '../service/hooks/orgGroups'
import { ERole } from '../service/enum'
import { setRedirectUrl } from '../utils/auth'

interface IState {
  orgGuid: string
  orgName: string
  isAssociate: boolean
  isManagerLead: boolean
  isIT: boolean
  isAccounting: boolean
  orgUser?: IOrgUser
  setOrgGuid: React.Dispatch<React.SetStateAction<string>>
  setOrgName: React.Dispatch<React.SetStateAction<string>>
}

const initialState: IState = {
  orgGuid: loadOrgGuid(),
  orgName: loadOrgName(),
  isAssociate: false,
  isManagerLead: false,
  isIT: false,
  isAccounting: false,
  setOrgGuid: () => null,
  setOrgName: () => null,
}

export const OrgGuidContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const OrgGuidContextProvider: React.FC<Props> = ({ children }) => {
  const [orgGuid, setOrgGuid] = useState<string>(loadOrgGuid())
  const [orgName, setOrgName] = useState<string>(loadOrgName() || 'No organization Selected.')
  const location = useLocation()
  const { data, isLoading } = useOrganizations(1)
  const { data: orgUser } = useOrgUser(orgGuid, 'me')
  const { data: orgGroups, isLoading: isGroupsLoading } = useListUserOrgGroups(orgGuid, 'me')

  const isAssociate = useMemo(() => {
    // only those with permission group Associate to have visibility to the dashboard
    const groupNames = orgGroups?.results.map((group) => group.name) || []
    return groupNames.includes('Associate')
  }, [orgGroups])

  const isManagerLead = useMemo(() => {
    // super admin should have visibility to the dashboard (for now)
    if (orgUser?.role === ERole.super_admin) return true

    // only those with permission group Manager or Manager & Lead to have visibility to the dashboard
    const groupNames = orgGroups?.results.map((group) => group.name) || []
    return groupNames.includes('Manager') || groupNames.includes('Lead')
  }, [orgGroups, orgUser])

  const isIT = useMemo(() => {
    const groupNames = orgGroups?.results.map((group) => group.name) || []
    return groupNames.includes('IT')
  }, [orgGroups])

  const isAccounting = useMemo(() => {
    const groupNames = orgGroups?.results.map((group) => group.name) || []
    return groupNames.includes('Accounting')
  }, [orgGroups])

  const isOrgGuidRequired =
    !orgGuid && Object.values(orgGuidRequiredRoutes).includes(location.pathname)

  useEffect(() => {
    if (!isLoading && !orgGuid) {
      if (data && data.count === 1) {
        const firstOrg = data.results[0]
        setOrgGuid(firstOrg.guid)
        setOrgName(firstOrg.name)
        saveOrgToStorage(firstOrg)
      }
    }
  }, [data, isLoading, orgGuid])

  if ((isOrgGuidRequired && isLoading) || isGroupsLoading) return <LoadingIndicator />

  if (!orgGuid && location.pathname !== orgRoutesPath.myOrganizations) {
    setRedirectUrl((location.pathname || '/') + (location.search || ''))
    return (
      <Redirect
        to={{ pathname: orgRoutesPath.myOrganizations, state: { referrer: location.pathname } }}
      />
    )
  }

  return (
    <OrgGuidContext.Provider
      value={{
        orgGuid,
        orgName,
        orgUser,
        isAssociate,
        isManagerLead,
        isIT,
        isAccounting,
        setOrgGuid,
        setOrgName,
      }}
    >
      {children}
    </OrgGuidContext.Provider>
  )
}
