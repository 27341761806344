import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  pageTitle: string
  description?: string
}

const SetPageTitle: React.FC<Props> = ({ pageTitle, description = '' }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default SetPageTitle
