import React, { useContext, useMemo } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import CustomDialog from '../CustomDialog'
import { IDateRange } from '../../service/interface'
import { getFormattedCurrency, getLocaleDateString } from '../../utils/helpers'
import { OrgGuidContext } from '../../contexts/OrgGuidContext'
import { useClientEntityBillSummary } from '../../service/hooks/orgBill'
import { CustomNoRowsOverlay } from '../table/EmptyTableState'

interface Props {
  open: boolean
  title: React.ReactNode
  selectedClient?: string
  selectedDateRange: IDateRange
  workflowState: string
  onClose: () => void
}

const ViewTotalModal: React.FC<Props> = ({
  open,
  title,
  selectedClient,
  selectedDateRange,
  workflowState,
  onClose,
}) => {
  const { orgGuid } = useContext(OrgGuidContext)
  const formatStartDate = useMemo(() => {
    if (!selectedDateRange.startDate) return
    return getLocaleDateString(selectedDateRange.startDate)
  }, [selectedDateRange.startDate])
  const formatEndDate = useMemo(() => {
    if (!selectedDateRange.endDate) return
    return getLocaleDateString(selectedDateRange.endDate)
  }, [selectedDateRange.endDate])
  const { data: clientEntitySummary } = useClientEntityBillSummary(orgGuid, {
    clients: selectedClient,
    invoiced_after: formatStartDate !== '-' ? formatStartDate : undefined,
    invoiced_before: formatEndDate !== '-' ? formatEndDate : undefined,
    workflow_state: workflowState,
  })
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      buttonCloseOnly
      contentDividers
      title={title}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </>
      }
      maxWidth="md"
    >
      {clientEntitySummary?.results.length === 0 &&
        CustomNoRowsOverlay('No total amount to display.')}
      {clientEntitySummary?.results.map((client) => {
        return (
          <>
            <Stack direction="row" mb={2} sx={{ padding: 1, backgroundColor: '#E6F0F7' }}>
              <Box flex={1}>
                <Typography fontWeight={350}>{client.client}</Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography fontWeight={350}>
                  Total:
                  <Typography ml={1} display="inline">
                    {getFormattedCurrency(client.client_total, 'USD')}
                  </Typography>
                </Typography>
              </Box>
            </Stack>
            {client.entities.map((entity) => {
              return (
                <Stack direction="row" mb={2} sx={{ paddingLeft: 3, paddingRight: 1 }}>
                  <Box flex={1}>
                    <Typography color="#394A64">{entity.entity}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography color="#394A64">
                      {getFormattedCurrency(entity.entity_total, 'USD')}
                    </Typography>
                  </Box>
                </Stack>
              )
            })}
          </>
        )
      })}
    </CustomDialog>
  )
}

export default ViewTotalModal
