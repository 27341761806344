import React from 'react'
import { TExtensionTypes } from '../../utils/helpers'
import DocPreviewer from './DocPreviewer'
import ImagePreviewer from './ImagePreviewer'
import PdfPreviewer from './PdfPreviewer'
import AudioPreviewer from './AudioPreviewer'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const HTMLRenderer = require('react-html-renderer')

interface Props {
  fileName: string
  eaGuid?: string
  file?: File
  ext: TExtensionTypes
  isOnboarding?: boolean
  isClientTemplate?: boolean
  isClient?: boolean
  isClientUser?: boolean
  createdOn?: string
  docGuid?: string
  clientGuid?: string
  destroy?: () => Promise<any>
}

const FilePreviewer: React.FC<Props> = ({
  fileName,
  eaGuid,
  file,
  ext,
  isOnboarding,
  isClientTemplate,
  isClient,
  isClientUser,
  createdOn,
  docGuid,
  clientGuid,
  destroy = async () => null,
}: Props) => {
  const previewFile = () => {
    switch (ext) {
      case 'pdf':
        return (
          <PdfPreviewer
            file={file ? file : fileName}
            key={eaGuid}
            destroy={destroy}
            isOnboarding={isOnboarding}
            createdOn={createdOn}
            docGuid={docGuid}
            clientGuid={clientGuid}
            isClientTemplate={isClientTemplate}
            isClient={isClient}
            isClientUser={isClientUser}
          />
        )
      case 'html':
        return <HTMLRenderer html={file?.text} />
      case 'txt':
        return <p>{file?.text}</p>
      case 'img':
        return (
          <ImagePreviewer
            file={file ? file : fileName}
            destroy={destroy}
            isOnboarding={isOnboarding}
            createdOn={createdOn}
            docGuid={docGuid}
            clientGuid={clientGuid}
            isClientTemplate={isClientTemplate}
            isClient={isClient}
            isClientUser={isClientUser}
          />
        )
      case 'wav':
        return <AudioPreviewer file={file ? file : fileName} destroy={destroy} />
      case 'doc' || 'docx' || 'xlsx' || 'xls':
        return (
          <DocPreviewer
            file={file ? file : fileName}
            destroy={destroy}
            isOnboarding={isOnboarding}
            createdOn={createdOn}
            docGuid={docGuid}
            clientGuid={clientGuid}
            isClientTemplate={isClientTemplate}
            isClient={isClient}
            isClientUser={isClientUser}
          />
        )
      default:
        return (
          <DocPreviewer
            file={file ? file : fileName}
            destroy={destroy}
            isOnboarding={isOnboarding}
            createdOn={createdOn}
            docGuid={docGuid}
            clientGuid={clientGuid}
            isClientTemplate={isClientTemplate}
            isClient={isClient}
            isClientUser={isClientUser}
          />
        )
    }
  }

  return <div>{previewFile()}</div>
}

export default FilePreviewer
