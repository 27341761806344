import React, { useContext, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Button,
  CircularProgress,
  List,
  Menu,
  Typography,
  Box,
  MenuItem,
  ListItemButton,
} from '@mui/material'
import ArrowLeft from '@mui/icons-material/ArrowLeft'
import {
  clearMaskAuthStorage,
  getMaskAccessToken,
  isUserStaffAdminOrMember,
} from '../../../utils/auth'
import { useRetrieveUser } from '../../../service/hooks/auth'
import { OrgGuidContext } from '../../../contexts/OrgGuidContext'
import HeaderDots from '../HeaderDots'
import { useGlobalBillMessageSummary } from '../../../service/hooks/orgBill'
import lineBorder from '../../../assets/images/line_border.png'
import { logout } from '../../../utils/auth'
import { useOrganizations } from '../../../service/hooks/organization'
import { IOrganization } from '../../../service/interface'
import { saveOrgToStorage } from '../../../utils/organization'
import { ERole } from '../../../service/enum'
import SearchIcon from '@mui/icons-material/Search'

interface HeaderUserBoxProps {
  isOnboarding?: boolean
}

const HeaderUserbox: React.FC<HeaderUserBoxProps> = ({ isOnboarding }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [orgAnchorEl, setOrgAnchorEl] = useState<null | HTMLElement>(null)
  const { orgName, orgGuid, setOrgGuid, setOrgName, orgUser } = useContext(OrgGuidContext)
  const { data } = useGlobalBillMessageSummary(orgGuid)
  const history = useHistory()

  const { data: userInfo, isLoading } = useRetrieveUser('me')
  const { data: orgListResponse, isLoading: isOrgLoading } = useOrganizations(1)

  const orgList = useMemo(() => orgListResponse?.results || [], [orgListResponse])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUnmask = () => {
    handleClose()
    clearMaskAuthStorage()
    window.location.href = '/'
  }

  const handleLogOut = () => {
    handleClose()
    logout()
  }

  const handleClickOrg = () => {
    const el = document.getElementById('menu-content')
    setOrgAnchorEl(el)
  }

  const handleCloseOrg = () => {
    setOrgAnchorEl(null)
  }

  const handleOrgChange = (org: IOrganization) => {
    return () => {
      saveOrgToStorage(org)
      setOrgGuid(org.guid)
      setOrgName(org.name)
      handleCloseOrg()
    }
  }

  const isMaskedUser = !!getMaskAccessToken()

  return (
    <>
      <Box display="flex" justifyContent="start" alignItems="end">
        {!isOnboarding && (
          <>
            <Button onClick={() => history.push('/advanced-search')}>
              <SearchIcon sx={{ color: 'white' }} />
            </Button>
            <HeaderDots unreadAmount={data?.unread_count || 0} guid={orgGuid} />
            <Box>
              <img alt="line border" src={lineBorder} />
            </Box>
          </>
        )}
        <Button
          variant="text"
          onClick={handleClick}
          sx={{ marginLeft: 1, padding: 0 }}
          disableRipple
        >
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <>
              <Box pl={1}>
                <Typography color="white">{`${userInfo?.first_name} ${userInfo?.last_name}`}</Typography>
              </Box>
              <Box pl={1}>
                <Avatar src={userInfo?.avatar}>{userInfo?.first_name.charAt(0)}</Avatar>
              </Box>
            </>
          )}
          <Box pl={2}>
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              style={{ color: 'white', opacity: 0.5 }}
            />
          </Box>
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}
      >
        <Box id="menu-content">
          <List component="div">
            <Button
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              onClick={handleClickOrg}
            >
              <Typography fontWeight={350} textTransform="capitalize" color="#2B388D">
                <ArrowLeft style={{ fontSize: 16 }} />
                {orgName}
              </Typography>
            </Button>
            <Menu
              id="org-switch-menu"
              anchorEl={orgAnchorEl}
              open={Boolean(orgAnchorEl)}
              onClose={handleCloseOrg}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{ list: 'p-0' }}
            >
              {!isOrgLoading &&
                !!orgList &&
                orgList.map((org) => (
                  <MenuItem onClick={handleOrgChange(org)} key={org.guid}>
                    <Typography>{org.name}</Typography>
                  </MenuItem>
                ))}
            </Menu>
            {(orgUser?.role === ERole.admin || orgUser?.role === ERole.super_admin) && (
              <Link to="/settings" className="nav-link-simple">
                <ListItemButton onClick={handleClose}>
                  <Typography color="black">Org Settings</Typography>
                </ListItemButton>
              </Link>
            )}
            <Link to="/audit-history" className="nav-link-simple">
              <ListItemButton onClick={handleClose}>
                <Typography color="black">Audit History</Typography>
              </ListItemButton>
            </Link>
            <Link to="/profile" className="nav-link-simple">
              <ListItemButton onClick={handleClose}>
                <Typography color="black">Profile</Typography>
              </ListItemButton>
            </Link>
            <Link to="/support" className="nav-link-simple">
              <ListItemButton onClick={handleClose}>
                <Typography color="black">Support</Typography>
              </ListItemButton>
            </Link>
            {!isMaskedUser && isUserStaffAdminOrMember() && (
              <Link to="/non-staff-users" className="nav-link-simple">
                <ListItemButton onClick={handleClose}>
                  <Typography color="black">Mask</Typography>
                </ListItemButton>
              </Link>
            )}
            {isMaskedUser && (
              <ListItemButton onClick={handleUnmask}>
                <Typography color="black">Unmask</Typography>
              </ListItemButton>
            )}
            <ListItemButton onClick={handleLogOut}>Logout</ListItemButton>
          </List>
        </Box>
      </Menu>
    </>
  )
}

export default HeaderUserbox
