import React from 'react'
import { EToastType } from '../../contexts/ToastContext'
import LoadingIndicator from '../loading/LoadingIndicator'
import { useEffect } from 'react'
import { useQBOSync } from '../../service/hooks/integrations'
import { ConnectionCompleteRedirect } from './ConnectionCompleteRedirect'

export const QBOEntityGuidProperty = 'QBOEntityGuid'

const QBOSyncRedirect = () => {
  const { mutateAsync: syncQBO, isSuccess, isError } = useQBOSync()

  useEffect(() => {
    // get entityGuid
    const entityGuid = window.localStorage.getItem(QBOEntityGuidProperty)
    window.localStorage.removeItem(QBOEntityGuidProperty)

    if (entityGuid) {
      syncQBO({ entityGuid })
    }
  })

  if (isSuccess) {
    return (
      <ConnectionCompleteRedirect
        toastType={EToastType.success}
        toastMessage="Successfully synced with Quickbooks Online"
      />
    )
  }

  if (isError) {
    return (
      <ConnectionCompleteRedirect
        toastType={EToastType.error}
        toastMessage="Failed to sync with Quickbooks Online"
      />
    )
  }
  return <LoadingIndicator />
}

export default QBOSyncRedirect
