import React, { useEffect, useState } from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import { subDays } from 'date-fns'
import { DateRangePickerProps } from '@mui/lab'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  styled,
  TextField,
} from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { IAuditHistorySearch, IDateRange } from '../../service/interface'

interface Props {
  hasMaxDate: boolean
  defaultRange?: IDateRange
  setSelectedDateRange?: React.Dispatch<React.SetStateAction<IDateRange>> | undefined
  setAuditInputFilter?: React.Dispatch<React.SetStateAction<IAuditHistorySearch>>
}

const StyledCancelBtn = styled(Button)(() => ({
  background: '#F8F7FA',
  borderRadius: '8px',
  border: '1px solid #F8F7FA',
  width: '79px',
  height: '40px',
}))

const StyledSaveBtn = styled(Button)(() => ({
  borderRadius: '8px',
  width: '93px',
  height: '40px',
}))

const calendarViewDays = {
  thirtyDays: {
    label: 'Last 30 Days',
    startDate: subDays(new Date(), 29),
    endDate: new Date(),
  },
  sixtyDays: {
    label: 'Last 60 Days',
    startDate: subDays(new Date(), 59),
    endDate: new Date(),
  },
  ninetyDays: {
    label: 'Last 90 Days',
    startDate: subDays(new Date(), 89),
    endDate: new Date(),
  },
}

const DateRangeFilter: React.FC<Props> = ({
  hasMaxDate,
  defaultRange,
  setSelectedDateRange,
  setAuditInputFilter,
}) => {
  const [startDate, setStartDate] = React.useState<string>('Start Date')
  const [endDate, setEndDate] = React.useState<string>('End Date')
  const [dateFieldDisplay, setDateFieldDisplay] = React.useState<string>()
  const onChange = (ranges: DateRangePickerProps) => {
    const startDate = new Date(ranges.startDate)
    const startDateStr =
      startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear()
    const endDate = new Date(ranges.endDate)
    const endDateStr =
      endDate.getMonth() + 1 + '/' + endDate.getDate() + '/' + endDate.getFullYear()
    setStartDate(startDateStr)
    setEndDate(endDateStr)
    if (setSelectedDateRange) {
      setSelectedDateRange({
        startDate: new Date(startDateStr),
        endDate: new Date(endDateStr),
      })
    }
    if (setAuditInputFilter) {
      setAuditInputFilter((prev) => ({
        ...prev,
        selectedDateRange: { startDate: new Date(startDateStr), endDate: new Date(endDateStr) },
      }))
    }
  }
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCancelBtn = () => {
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ])
    setAnchorEl(null)
    setDateFieldDisplay(undefined)
    setStartDate('Start Date')
    setEndDate('End Date')
    if (setSelectedDateRange) {
      setSelectedDateRange({
        startDate: undefined,
        endDate: undefined,
      })
    }
    if (setAuditInputFilter) {
      setAuditInputFilter((prev) => ({
        ...prev,
        selectedDateRange: { startDate: undefined, endDate: undefined },
      }))
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleOnChange = (ranges: DateRangePickerProps) => {
    const { selection } = ranges
    const startDateOnChange = new Date(selection['startDate']).toDateString()
    const endDateOnChange = new Date(selection['endDate']).toDateString()
    const thirtyDaysStartDate = calendarViewDays.thirtyDays.startDate.toDateString()
    const thirtyDaysEndDate = calendarViewDays.thirtyDays.endDate.toDateString()
    const sixtyDaysStartDate = calendarViewDays.sixtyDays.startDate.toDateString()
    const sixtyDaysEndDate = calendarViewDays.sixtyDays.endDate.toDateString()
    const ninetyDaysStartDate = calendarViewDays.ninetyDays.startDate.toDateString()
    const ninetyDaysEndDate = calendarViewDays.ninetyDays.endDate.toDateString()
    if (startDateOnChange === thirtyDaysStartDate && endDateOnChange === thirtyDaysEndDate) {
      setDateFieldDisplay(calendarViewDays.thirtyDays.label)
    } else if (startDateOnChange === sixtyDaysStartDate && endDateOnChange === sixtyDaysEndDate) {
      setDateFieldDisplay(calendarViewDays.sixtyDays.label)
    } else if (startDateOnChange === ninetyDaysStartDate && endDateOnChange === ninetyDaysEndDate) {
      setDateFieldDisplay(calendarViewDays.ninetyDays.label)
    } else {
      setDateFieldDisplay(undefined)
    }
    onChange(selection)
    setState([selection])
  }

  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: calendarViewDays.thirtyDays.label,
        isSelected() {
          return true
        },
        range: () => calendarViewDays.thirtyDays,
      },
      {
        label: 'Last 60 Days',
        isSelected() {
          return true
        },
        range: () => calendarViewDays.sixtyDays,
      },
      {
        label: 'Last 90 Days',
        isSelected() {
          return true
        },
        range: () => calendarViewDays.ninetyDays,
      },
    ]

    return customDateObjects
  }
  const staticRanges = [...createStaticRanges(sideBarOptions())]

  useEffect(() => {
    if (defaultRange && defaultRange.endDate && defaultRange.startDate) {
      const startDate = defaultRange.startDate
      const startDateStr =
        startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear()
      const endDate = defaultRange.endDate
      const endDateStr =
        endDate.getMonth() + 1 + '/' + endDate.getDate() + '/' + endDate.getFullYear()
      setStartDate(startDateStr)
      setEndDate(endDateStr)
    }
  }, [defaultRange])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <TextField
          name="date_range"
          value={dateFieldDisplay ? dateFieldDisplay : startDate + ' to ' + endDate}
          required
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <CalendarMonthIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            handleClick(event)
          }}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <DateRangePicker
            onChange={handleOnChange}
            staticRanges={staticRanges}
            moveRangeOnFirstSelection={false}
            inputRanges={[]}
            months={2}
            ranges={state}
            direction="horizontal"
            rangeColors={['#2B388D']}
            maxDate={hasMaxDate ? new Date() : undefined}
            calendarFocus="backwards"
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end">
          <Stack direction="row">
            <Box p={2}>
              <StyledCancelBtn variant="outlined" onClick={handleCancelBtn}>
                Cancel
              </StyledCancelBtn>
            </Box>
            <Box mr={2} mt={2}>
              <StyledSaveBtn variant="contained" onClick={handleClose}>
                Set Date
              </StyledSaveBtn>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </LocalizationProvider>
  )
}

export default DateRangeFilter
