import {
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid'
import { InputNumberInterval } from '../components/Filter/InputNumberInterval'

export const dateFilterOperators = getGridDateOperators().filter(
  (operator) => operator.value === 'before' || operator.value === 'after'
)
export const stringFilterOperators = getGridStringOperators().filter(
  (operator) => operator.value === 'contains'
)

export const selectFilterOperators = getGridSingleSelectOperators().filter(
  (operator) => operator.value === 'is'
)

function generateFieldObject(field: string, value: any[], multiplier: number) {
  return [
    {
      field: `${field}_min`,
      value: value[0] ? value[0] * multiplier : undefined,
    },
    {
      field: `${field}_max`,
      value: value[1] ? value[1] * multiplier : undefined,
    },
  ]
}

export const amountFilterOperators: GridFilterOperator[] = [
  {
    label: 'Between',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null
      }
      return ({ value }): boolean => {
        return value != null && filterItem.value[0] <= value && value <= filterItem.value[1]
      }
    },
    InputComponent: InputNumberInterval,
  },
]

export const processBillFilter = (filterModel: GridFilterModel) => {
  const item = filterModel.items[0]
  if (!item) return undefined

  const field = item.columnField as string
  // Split the field by '__' and get the last part
  const fieldWithoutPrefix = field.split('__').pop()?.replace('_on', '')
  const operator = item.operatorValue as string
  if (field.endsWith('_on')) {
    return {
      field: `${fieldWithoutPrefix}_${operator}`,
      value: item.value,
    }
  }
  if (field === 'workflow__state') {
    return {
      field: 'workflow_state',
      value: item.value,
    }
  }
  if (field === 'payment_method') {
    return {
      field: 'payment_methods',
      value: item.value,
    }
  }
  if (field === 'payment_status') {
    return {
      field: 'is_fully_paid',
      value: item.value,
    }
  }
  if (field === 'amount' || field === 'remaining_balance') {
    if (operator === 'between' && Array.isArray(item.value)) {
      return generateFieldObject(field, item.value, 100)
    }
  }
  if (field.includes('__name')) {
    return {
      field: field,
      value: item.value,
    }
  } else {
    return {
      field: fieldWithoutPrefix || field,
      value: item.value,
    }
  }
}

export const processCommitmentFilter = (filterModel: GridFilterModel) => {
  const item = filterModel.items[0]
  if (!item) return undefined

  const field = item.columnField as string
  const operator = item.operatorValue as string
  if (field.endsWith('_on')) {
    return {
      field: `${field}_${operator}`,
      value: item.value,
    }
  }
  if (field === 'estimated_amount') {
    if (operator === 'between' && Array.isArray(item.value)) {
      return generateFieldObject(field, item.value, 100)
    }
  }
  return {
    field: field,
    value: item.value,
  }
}

export const processEntityFilter = (filterModel: GridFilterModel) => {
  const item = filterModel.items[0]
  if (!item) return undefined

  const field = item.columnField as string
  const operator = item.operatorValue as string
  if (field.endsWith('_on')) {
    return {
      field: `${field}_${operator}`,
      value: item.value,
    }
  }
  if (
    field === 'allowable_payment_threshold' ||
    field === 'approve_up_to' ||
    field === 'bank_account_balance'
  ) {
    if (operator === 'between' && Array.isArray(item.value)) {
      if (field === 'bank_account_balance') {
        return generateFieldObject(field, item.value, 1)
      }
      return field === 'approve_up_to'
        ? generateFieldObject(field, item.value, 100)
        : generateFieldObject(field, item.value, 1 / 100)
    }
  }
  return {
    field: field,
    value: item.value,
  }
}

export const processFilter = (filterModel: GridFilterModel) => {
  const item = filterModel.items[0]
  if (!item) return undefined

  const field = item.columnField as string
  const operator = item.operatorValue as string
  if (field.endsWith('_on')) {
    return {
      field: `${field}_${operator}`,
      value: item.value,
    }
  }
  return {
    field: field,
    value: item.value,
  }
}
