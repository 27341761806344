import React, { useContext, useState } from 'react'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { clientBillRoutes, clientRoutesPath } from '../../../routes/ClientRoutes'
import { IDateRange, IEntity } from '../../../service/interface'
import BillTabs from './BillTabs'
import DateRangeFilter from '../../../components/DateFilter'
import ClientUploadBill from '../ClientUploadBill'
import { ERole, EWorkflowEventAction, EWorkflowState } from '../../../service/enum'
import { IBillingTab } from './interface'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { ClientGuidContext } from '../../../contexts/ClientGuidContext'
import { BillParamsContext } from '../../../contexts/BillParamsContext'

const InfoTooltip: React.FC = () => {
  const [isHovered, setHovered] = useState(false)

  const defaultSx = {
    position: 'absolute',
    right: -(18 + 24),
    height: 24,
    width: 24,
  }

  return (
    <Tooltip
      title='Upload a bill by clicking "Upload a Bill for Processing" and your Plumb team will process the bill on your behalf.'
      arrow
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {isHovered ? (
        <InfoIcon
          sx={{
            ...defaultSx,
            color: 'primary.main',
          }}
        />
      ) : (
        <InfoOutlinedIcon
          sx={{
            ...defaultSx,
            color: '#A4B0C0',
          }}
        />
      )}
    </Tooltip>
  )
}

interface Props {
  entities: IEntity[]
  clientGuid: string
}

export const defaultDateRange: IDateRange = {
  startDate: undefined,
  endDate: undefined,
}

const BillList: React.FC<Props> = ({ entities, clientGuid }: Props) => {
  const [isOpenUploadDialog, setOpenUploadDialog] = useState(false)
  const { entityGuid } = useParams<{ entityGuid: string }>()
  const { clientRole } = useContext(ClientGuidContext)
  const { selectedDateRange, setSelectedDateRange } = useContext(BillParamsContext)

  const tabs: IBillingTab[] = [
    {
      label: 'Pre-bill',
      path: `${clientRoutesPath.bills}/${entityGuid}/client-pre-bill`,
      workflowState: EWorkflowState.pre_bill,
      dateFilterKey: {
        startDate: 'invoiced_after',
        endDate: 'invoiced_before',
      },
      clientApprovalAction: undefined,
      excludedColumns: [
        'Verbal Required',
        'Remaining Balance',
        'Paid Date',
        'Approved Date',
        'Due Date',
        'Approval Category',
        'Rejected Date',
        'Payment Method',
        'Reason',
        'Payment Type',
        'Payee',
        'Status',
        '✅',
        '',
      ],
    },
    {
      label: 'Needs Approval',
      path: `${clientRoutesPath.bills}/${entityGuid}`,
      workflowState: EWorkflowState.client_approval,
      dateFilterKey: {
        startDate: 'invoiced_after',
        endDate: 'invoiced_before',
      },
      clientApprovalAction: undefined,
      excludedColumns: [
        'Verbal Required',
        'Remaining Balance',
        'Paid Date',
        'Approved Date',
        'Due Date',
        'Approval Category',
        'Rejected Date',
        'Payment Method',
        'Reason',
        'Payment Type',
        'Vendor',
        'Status',
        'Client',
        '✅',
        '',
      ],
    },
    {
      label: 'Approved',
      path: clientBillRoutes.billsApproved(entityGuid),
      workflowState: [EWorkflowState.poa_approval, EWorkflowState.payment_pending].join(','),
      dateFilterKey: {
        startDate: 'client_approval_after',
        endDate: 'client_approval_before',
      },
      clientApprovalAction: EWorkflowEventAction.approved,
      excludedColumns: [
        'Verbal Required',
        'Remaining Balance',
        'Paid Date',
        'Due Date',
        'Actions',
        'Rejected Date',
        'Reason',
        'Payment Type',
        'Vendor',
        'Status',
        'Client',
        '✅',
        '',
      ],
    },
    {
      label: 'Rejected',
      path: clientBillRoutes.billsRejected(entityGuid),
      workflowState: EWorkflowState.rejected,
      dateFilterKey: {
        startDate: 'rejected_after',
        endDate: 'rejected_before',
      },
      clientApprovalAction: EWorkflowEventAction.rejected,
      excludedColumns: [
        'Client',
        'Verbal Required',
        'Remaining Balance',
        'Paid Date',
        'Approved Date',
        'Approval Category',
        'Due Date',
        'Actions',
        'Payment Method',
        'Payment Type',
        'Vendor',
        'Status',
        '✅',
        '',
      ],
    },
    {
      label: 'Paid',
      path: clientBillRoutes.billsPaid(entityGuid),
      workflowState: EWorkflowState.paid,
      dateFilterKey: {
        startDate: 'paid_after',
        endDate: 'paid_before',
      },
      clientApprovalAction: undefined,
      excludedColumns: [
        'Client',
        'Verbal Required',
        'Approved Date',
        'Rejected Date',
        'Approval Category',
        'Reason',
        'Due Date',
        'Actions',
        'Vendor',
        'Status',
        '',
      ],
    },
  ]

  return (
    <Box>
      <Box mt={1} display="flex" flexDirection="row" position="relative">
        <Typography fontSize={20} fontWeight={350} flexGrow={1}>
          Bills
        </Typography>
        <Box mr={2}>
          <DateRangeFilter
            hasMaxDate={true}
            defaultRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
        </Box>
        {clientRole !== ERole.observer && (
          <>
            <Button
              variant="contained"
              onClick={() => setOpenUploadDialog(true)}
              sx={{ height: 40 }}
            >
              Upload a Bill for Processing
            </Button>
            <ClientUploadBill
              clientGuid={clientGuid}
              open={isOpenUploadDialog}
              onClose={() => setOpenUploadDialog(false)}
            />
            <InfoTooltip />{' '}
          </>
        )}
      </Box>
      <Box mt={2} bgcolor="white" p="25px">
        <BillTabs
          type="client"
          guid={clientGuid}
          entities={entities}
          tabs={tabs}
          selectedDateRange={selectedDateRange}
        />
      </Box>
    </Box>
  )
}

export default BillList
