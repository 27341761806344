import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { clientRoutesPath } from '../../../routes/ClientRoutes'

import {
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
  Box,
  FormControl,
  Select,
  styled,
  SelectChangeEvent,
} from '@mui/material'
import SidebarHeader from '../SidebarHeader'
import HeaderUserbox from '../ClientHeaderUserbox'
import MenuIcon from '@mui/icons-material/Menu'
import { box_minimized_style, box_maximized_style, menu_style, client_pages } from '../constants'
import { ClientGuidContext } from '../../../contexts/ClientGuidContext'
import { saveClientToStorage, saveIsOnboardingCompleted } from '../../../utils/client'
import { StyledItemText, StyledLabelText } from './OrgSidebarMenu'
import { StyledAppBar } from '../../../utils/styles'

const StyledSelect = styled(Select)({
  backgroundColor: '#505BA1',
  color: 'white',
  borderRadius: 4,
  '&:hover': {
    backgroundColor: '#505BA1',
  },
})

const ClientSidebarMenu: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const bills_page = clientRoutesPath.bills + '/all-entities'
  const pages_to = [
    clientRoutesPath.dashboard,
    clientRoutesPath.commitments + '/all-entities',
    bills_page,
    clientRoutesPath.reports,
  ]

  const {
    clientGuid,
    setClientGuid,
    setClientName,
    setIsOnboardingCompleted,
    clientList,
    isClientLoading,
    isOnboardingCompleted,
  } = useContext(ClientGuidContext)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleClientChange = (event: SelectChangeEvent<unknown>) => {
    const newClientGuid = event.target.value
    const client = clientList.find((client) => client.guid === newClientGuid)
    if (client) {
      saveClientToStorage(client)
      setClientGuid(client.guid)
      setClientName(client.name)
      setIsOnboardingCompleted(client.is_onboarding_completed ? '1' : '0')
      saveIsOnboardingCompleted(client.is_onboarding_completed ? '1' : '0')
    }
  }

  return (
    <>
      <StyledAppBar>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box flex={1} sx={box_minimized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_minimized_style}>
              <Box>
                <IconButton
                  size="large"
                  aria-label="menu-bar"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  className="icon-button"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={menu_style}
              >
                {client_pages.map((page, index) => (
                  <MenuItem key={page}>
                    <NavLink className="nav-link-simple" to={pages_to[index]}>
                      <StyledItemText>{page}</StyledItemText>
                    </NavLink>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={box_maximized_style} flex={isOnboardingCompleted === '1' ? undefined : 1}>
              <SidebarHeader />
            </Box>
            {isOnboardingCompleted === '1' && (
              <>
                {!!clientList.length && (
                  <Box sx={{ width: 228 }}>
                    <FormControl>
                      <StyledSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={clientGuid}
                        label="Client"
                        onChange={handleClientChange}
                      >
                        {!isClientLoading &&
                          !!clientList &&
                          clientList.map((client) => (
                            <MenuItem value={client.guid} key={client.guid}>
                              <Typography>{client.name}</Typography>
                            </MenuItem>
                          ))}
                      </StyledSelect>
                    </FormControl>
                  </Box>
                )}
                <Box flex={1} sx={box_maximized_style}>
                  <NavLink className="nav-link-simple" to={pages_to[0]}>
                    <StyledLabelText>{client_pages[0]}</StyledLabelText>
                  </NavLink>
                  <NavLink className="nav-link-simple" to={pages_to[1]}>
                    <StyledLabelText>{client_pages[1]}</StyledLabelText>
                  </NavLink>
                  <NavLink
                    className="nav-link-simple"
                    to={pages_to[2]}
                    isActive={(match, location) => {
                      if (location.pathname.includes('bill')) return true
                      return false
                    }}
                  >
                    <StyledLabelText>{client_pages[2]}</StyledLabelText>
                  </NavLink>
                  <NavLink className="nav-link-simple" to={pages_to[3]}>
                    <StyledLabelText>{client_pages[3]}</StyledLabelText>
                  </NavLink>
                </Box>
              </>
            )}
            <Box sx={box_maximized_style}>
              <HeaderUserbox />
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
    </>
  )
}

export default ClientSidebarMenu
