import React, { FormEventHandler, useCallback, useContext } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CustomDialog from '../CustomDialog'
import { useDropzone } from 'react-dropzone'
import { UseFormMethods } from 'react-hook-form'
import FilePreviewer from '../previewer/FilePreviewer'
import { getFileExtension, IMG_FILE_EXT, TExtensionTypes } from '../../utils/helpers'
import { UserType } from '../../service/interface'
import { ERole } from '../../service/enum'
import { EToastType, ToastContext } from '../../contexts/ToastContext'

interface Props {
  attms: File[]
  setAttms: React.Dispatch<React.SetStateAction<File[]>>
  open: boolean
  onClose: () => void
  isUploading: boolean
  register: UseFormMethods['register']
  onUpload: FormEventHandler
  onUploadApprove?: FormEventHandler
  title: React.ReactNode
  userType: UserType
  userRole?: string
}

const UploadBillDialog: React.FC<Props> = ({
  attms,
  setAttms,
  open,
  onClose,
  isUploading,
  register,
  onUpload,
  title,
  userType,
  onUploadApprove,
  userRole,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setAttms((prev: any[]) => [...prev, ...acceptedFiles])
    },
    [setAttms]
  )
  const { showToast } = useContext(ToastContext)
  const { getRootProps, getInputProps, isDragReject, isDragActive } = useDropzone({
    onDrop,
    accept: ['application/pdf', 'image/*'],
    onDropRejected() {
      return showToast({
        type: EToastType.error,
        header: 'Invalid file type',
        message: 'Unable to upload file.',
      })
    },
  })

  const removeAttachment = async (file: File) => setAttms(attms.filter((obj) => obj !== file))

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      buttonCloseOnly
      contentDividers
      title={title}
      actions={
        <>
          {userType === 'client' ? (
            <>
              <Box ml={2} flexGrow={2} justifyContent="flex-start">
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
              </Box>
              <Box mr={2}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isUploading || attms.length === 0}
                  onClick={onUpload}
                >
                  Upload
                </Button>
              </Box>
              {userRole && userRole === ERole.client && (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isUploading || attms.length === 0}
                    onClick={onUploadApprove}
                  >
                    Upload and Approve
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isUploading || attms.length === 0}
              >
                Upload
              </Button>
            </>
          )}
        </>
      }
    >
      <Box id="attachment-section" mb={3}>
        <Typography
          component="label"
          htmlFor="attachment-field"
          fontSize={14}
          fontWeight={350}
          mb={1}
        >
          Attachment
        </Typography>
        {isDragReject && (
          <Typography color="red" fontWeight={350}>
            Error: Only accepts PDF and image file.
          </Typography>
        )}
        <Box {...getRootProps()} border="1px dashed gray" textAlign="center" py="30px">
          <input id="attachment-field" {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the files here ...</Typography>
          ) : (
            <>
              <CloudUploadIcon sx={{ color: '#A4B0C0', mb: 1 }} />
              <Typography fontSize={14} fontWeight={325} px={2}>
                <Typography component="span" fontSize={14} fontWeight={350} color="primary">
                  Upload a file
                </Typography>{' '}
                or drag and drop
              </Typography>
            </>
          )}
        </Box>
        <Box>
          {attms &&
            attms.map((file) => {
              const fileExt = getFileExtension(file.name)
              const isFileImage = IMG_FILE_EXT.some((el) => el === fileExt)
              const fileType = isFileImage ? 'img' : fileExt
              return (
                <FilePreviewer
                  fileName={file.name}
                  file={file}
                  ext={fileType as TExtensionTypes}
                  destroy={() => removeAttachment(file)}
                />
              )
            })}
        </Box>
      </Box>

      <Box id="description-section">
        <Typography
          component="label"
          htmlFor="description-field"
          fontSize={14}
          fontWeight={350}
          mb={1}
        >
          Description (optional)
        </Typography>
        <TextField
          id="description-field"
          name="body"
          hiddenLabel
          multiline
          fullWidth
          rows={4}
          InputProps={{ sx: { fontSize: '14px', fontWeight: 325 } }}
          inputRef={register}
        />
      </Box>
    </CustomDialog>
  )
}

export default UploadBillDialog
