import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'
import {
  archiveEmail,
  createInboxEmail,
  createInboxEmailAttachment,
  destroyEmailAttachment,
  listClientEmailAttachments,
  listInboxEmails,
  listInboxEmailSummaries,
  listOrgEmailAttachments,
  unarchiveEmail,
  createOrgInboxEmail,
  createOrgInboxEmailAttachment,
} from '../api'
import {
  IArchiveEmailRequest,
  ICreateEmailAttachmentRequest,
  IDeleteEmailRequest,
  IEmail,
  IError,
  IListFileAttachments,
  IListInboxEmailRequest,
  IListInboxEmailSummary,
  ICreateOrgEmailAttachmentRequest,
} from '../interface'

export const EmailQueryKeys = {
  GET_INBOX_EMAILS: 'GET_INBOX_EMAILS',
  GET_INBOX_EMAIL_SUMMARIES: 'GET_INBOX_EMAIL_SUMMARIES',
  GET_ORG_EMAIL_ATTACHMENTS: 'GET_ORG_EMAIL_ATTACHMENTS',
  GET_CLIENT_EMAIL_ATTACHMENTS: 'GET_CLIENT_EMAIL_ATTACHMENTS',
}

export const useInboxEmails = (orgGuid: string, payload: IListInboxEmailRequest) =>
  useQuery(
    [EmailQueryKeys.GET_INBOX_EMAILS, orgGuid, payload],
    () => listInboxEmails(orgGuid, payload),
    { enabled: payload.clients !== 'all-clients' }
  )

export const useInboxEmailSummaries = (orgGuid: string, clients?: string) =>
  useQuery<IListInboxEmailSummary, IError>(
    [EmailQueryKeys.GET_INBOX_EMAIL_SUMMARIES, { orgGuid, clients }],
    () => listInboxEmailSummaries(orgGuid, clients)
  )

export const useArchiveEmail = (orgGuid: string, emailGuid: string) =>
  useMutation<undefined, IError, IArchiveEmailRequest>(() => archiveEmail(orgGuid, emailGuid))

export const useUnarchiveEmail = (orgGuid: string, emailGuid: string) =>
  useMutation<undefined, IError, IArchiveEmailRequest>(() => unarchiveEmail(orgGuid, emailGuid))

export const useOrgEmailAttachments = (
  orgGuid: string,
  emailGuid: string,
  config?: UseQueryOptions<IListFileAttachments, IError, IListFileAttachments>,
  page?: number
) =>
  useQuery<IListFileAttachments, IError>(
    [EmailQueryKeys.GET_ORG_EMAIL_ATTACHMENTS, { orgGuid, emailGuid, page }],
    () => listOrgEmailAttachments(orgGuid, emailGuid, page),
    config
  )

export const useDestroyOrgEmailAttachment = (orgGuid: string, emailGuid: string, eaGuid: string) =>
  useMutation<undefined, IError, IDeleteEmailRequest>(() =>
    destroyEmailAttachment({ orgGuid, emailGuid, eaGuid })
  )

export const useClientEmailAttachments = (
  clientGuid: string,
  emailGuid: string,
  config?: UseQueryOptions<IListFileAttachments, IError, IListFileAttachments>,
  page?: number
) =>
  useQuery<IListFileAttachments, IError>(
    [EmailQueryKeys.GET_CLIENT_EMAIL_ATTACHMENTS, { clientGuid, emailGuid, page }],
    () => listClientEmailAttachments(clientGuid, emailGuid, page),
    config
  )

export const useCreateClientInboxEmail = (
  options?: UseMutationOptions<
    IEmail,
    IError,
    {
      subject: string
      body: string
      clientGuid: string
      is_auto_is_prebill_auto_approvedapproved?: boolean
    }
  >
) =>
  useMutation<
    IEmail,
    IError,
    {
      subject: string
      body: string
      clientGuid: string
      is_prebill_auto_approved?: boolean
    }
  >(createInboxEmail, options)

export const useCreateClientEmailAttachment = () =>
  useMutation<IListFileAttachments, IError, ICreateEmailAttachmentRequest>(
    createInboxEmailAttachment
  )

export const useCreateOrgInboxEmail = (
  options?: UseMutationOptions<
    IEmail,
    IError,
    {
      subject: string
      body: string
      clientGuid: string
      orgGuid: string
    }
  >
) =>
  useMutation<
    IEmail,
    IError,
    {
      subject: string
      body: string
      clientGuid: string
      orgGuid: string
    }
  >(createOrgInboxEmail, options)

export const useCreateOrgEmailAttachment = () =>
  useMutation<IListFileAttachments, IError, ICreateOrgEmailAttachmentRequest>(
    createOrgInboxEmailAttachment
  )
