import React, { ReactElement, useState } from 'react'
import { Box, styled } from '@mui/material'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout'
import { SelectionMode } from '@react-pdf-viewer/selection-mode'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import Previewer from './Previewer'
import { ReactComponent as PDFIcon } from '../../assets/images/icons/pdf_icon.svg'

const SlotBox = styled(Box)({
  padding: '0 2px',
})

// based from https://react-pdf-viewer.dev/examples/create-a-toolbar-with-different-slots-for-the-default-layout/
const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const {
        CurrentPageInput,
        Download,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots
      return (
        <Box width="100%" display="flex" alignItems="center">
          <SlotBox>
            <ShowSearchPopover />
          </SlotBox>
          <SlotBox>
            <ZoomOut />
          </SlotBox>
          <SlotBox>
            <Zoom />
          </SlotBox>
          <SlotBox>
            <ZoomIn />
          </SlotBox>
          <SlotBox marginLeft="auto">
            <GoToPreviousPage />
          </SlotBox>
          <SlotBox width="50px" marginRight="12px">
            <CurrentPageInput />
          </SlotBox>
          <SlotBox>/</SlotBox>
          <SlotBox>
            <NumberOfPages />
          </SlotBox>
          <SlotBox>
            <GoToNextPage />
          </SlotBox>
          <SlotBox marginLeft="auto">
            <Download />
          </SlotBox>
        </Box>
      )
    }}
  </Toolbar>
)

interface Props {
  file: string | File
  destroy: () => Promise<any>
  fileType?: string
  isOnboarding?: boolean
  isClientTemplate?: boolean
  isClient?: boolean
  isClientUser?: boolean
  createdOn?: string
  docGuid?: string
  clientGuid?: string
}

const PdfPreviewer: React.FC<Props> = ({
  file,
  destroy,
  fileType,
  isOnboarding,
  isClientTemplate,
  isClient,
  isClientUser,
  createdOn,
  docGuid,
  clientGuid,
}: Props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      selectionModePlugin: {
        selectionMode: SelectionMode.Hand,
      },
    },
    sidebarTabs: () => [],
    renderToolbar,
  })

  const [expanded, setExpanded] = useState<boolean>(false)

  const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file)

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <Previewer
        file={file}
        fileUrl={fileUrl}
        fileType={fileType}
        expanded={expanded}
        attachmentIcon={PDFIcon}
        setExpanded={setExpanded}
        destroy={destroy}
        isOnboarding={isOnboarding}
        createdOn={createdOn}
        docGuid={docGuid}
        clientGuid={clientGuid}
        isClientTemplate={isClientTemplate}
        isClient={isClient}
        isClientUser={isClientUser}
      />
      {expanded && (
        <>
          <Box height="520px" overflow="auto">
            <Box
              style={{
                height: '100%',
              }}
            >
              <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
            </Box>
          </Box>
        </>
      )}
    </Worker>
  )
}

export default PdfPreviewer
