import React from 'react'
import { Chip } from '@mui/material'

interface Props {
  isFullyPaid: boolean
}

const BillPaymentStatusChip: React.FC<Props> = ({ isFullyPaid }) => {
  let label, color, bgcolor

  if (isFullyPaid) {
    label = 'Paid in Full'
    color = '#4CAF50'
    bgcolor = 'rgba(46, 125, 50, 0.12)'
  } else {
    label = 'Partial Payment'
    color = '#ED6C02'
    bgcolor = 'rgba(237, 108, 2, 0.3)'
  }

  return <Chip label={label} sx={{ color, bgcolor }} />
}

export default BillPaymentStatusChip
