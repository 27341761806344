import React, { useState } from 'react'
import { Box } from '@mui/material'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import Previewer from './Previewer'
import AudioIcon from '@mui/icons-material/AudioFile'

interface Props {
  file: string | File
  destroy: () => Promise<any>
  fileType?: string
}

const AudioPreviewer: React.FC<Props> = ({ file, destroy, fileType }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file)

  return (
    <>
      <Previewer
        file={file}
        fileUrl={fileUrl}
        fileType={fileType}
        expanded={expanded}
        setExpanded={setExpanded}
        destroy={destroy}
        attachmentIcon={AudioIcon}
      />
      {expanded && (
        <>
          <Box
            sx={{
              height: '100%',
            }}
          >
            <audio controls>
              <source src={fileUrl} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        </>
      )}
    </>
  )
}

export default AudioPreviewer
