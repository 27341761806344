import React from 'react'
import { NavLink } from 'react-router-dom'
import projectLogo from '../../../assets/images/all_white_logo.png'
import minimizedLogo from '../../../assets/images/white_minimized_logo.png'
import {
  StyledAppSideBarCollapsed,
  StyledAppSideBarExpanded,
  StyledAppSideBarHeader,
} from '../../../utils/styles'

const SidebarHeader: React.FC = () => {
  return (
    <>
      <StyledAppSideBarHeader>
        <StyledAppSideBarCollapsed>
          <NavLink to="/" title="Plumb Bill Pay" className="app-sidebar-logo">
            <img alt="Billpay Logo" src={minimizedLogo} />
          </NavLink>
        </StyledAppSideBarCollapsed>
        <StyledAppSideBarExpanded>
          <NavLink to="/" title="Plumb Bill Pay" className="app-sidebar-logo">
            <img alt="Billpay Logo" src={projectLogo} />
          </NavLink>
        </StyledAppSideBarExpanded>
      </StyledAppSideBarHeader>
    </>
  )
}

export default SidebarHeader
