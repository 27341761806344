import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { use2FACheck, useAuthentication } from '../../service/hooks/auth'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...restProps }: RouteProps) => {
  const { loading, isAuthenticated } = useAuthentication()
  const shouldEnable2FA = use2FACheck(isAuthenticated)

  if (loading) return <></>

  // return isAuthenticated && !shouldEnable2FA ? <Route {...restProps} /> : <Redirect to="/login" />
  return (
    <Route
      {...restProps}
      render={({ location }) =>
        isAuthenticated && !shouldEnable2FA ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
