import React, { useContext, useState } from 'react'
import { Alert, AlertTitle, Snackbar, SvgIcon, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import {
  useCreateClientEmailAttachment,
  useCreateClientInboxEmail,
} from '../../service/hooks/email'
import { EToastType, ToastContext } from '../../contexts/ToastContext'
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check_icon_success.svg'
import UploadBillDialog from '../../components/modals/UploadBillDialog'
import { useClientUser } from '../../service/hooks/clientUser'

interface ICreateEmailForm {
  body: string
}

interface Props {
  clientGuid: string
  open: boolean
  onClose: () => void
}

const ClientUploadBill: React.FC<Props> = ({ clientGuid, open, onClose }: Props) => {
  const { data: clientUser } = useClientUser(clientGuid, 'me')
  const [attms, setAttms] = useState<File[]>([])
  const { showToast } = useContext(ToastContext)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const { register, handleSubmit, reset } = useForm<ICreateEmailForm>()
  const { mutateAsync: requestCreateClientEmailAttachment } = useCreateClientEmailAttachment()
  const { mutateAsync: requestCreateClientInboxEmail, isLoading } = useCreateClientInboxEmail({
    onSuccess: async (response) => {
      try {
        for (const i of attms) {
          const formData = new FormData()
          formData.append('file', i)
          await requestCreateClientEmailAttachment({
            clientGuid,
            emailGuid: response.guid,
            file: formData,
          })
        }
        setSnackbarOpen(true)
        setAttms([])
        reset()
        onClose()
      } catch (e) {
        showToast({ type: EToastType.error, message: 'Failed to upload attachments.' })
        onClose()
      }
    },
    onError: () => {
      showToast({ type: EToastType.error, message: 'Failed to upload bill.' })
      onClose()
    },
  })

  const onSubmit = async ({ body }: ICreateEmailForm) => {
    await requestCreateClientInboxEmail({
      subject: 'New pre-bill',
      body,
      clientGuid,
    })
  }

  const onSubmitApprove = async ({ body }: ICreateEmailForm) => {
    await requestCreateClientInboxEmail({
      subject: 'New pre-bill',
      body,
      clientGuid,
      is_prebill_auto_approved: true,
    })
  }

  return (
    <>
      <UploadBillDialog
        attms={attms}
        setAttms={setAttms}
        open={open}
        onClose={onClose}
        isUploading={isLoading}
        register={register}
        onUpload={handleSubmit(onSubmit)}
        onUploadApprove={handleSubmit(onSubmitApprove)}
        userType="client"
        userRole={clientUser?.role}
        title={
          <>
            <Typography fontSize={16} fontWeight={350}>
              Upload a Bill for Processing
            </Typography>
            <Typography fontSize={12} fontWeight={325}>
              The bill will appear in your view once processed by the Plumb Team.
            </Typography>
          </>
        }
      />
      <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }} open={isSnackbarOpen}>
        <Alert
          icon={<SvgIcon component={CheckIcon} />}
          severity="success"
          onClose={() => setSnackbarOpen(false)}
          sx={{ bgcolor: 'white' }}
        >
          <AlertTitle>
            You have successfully submitted a bill to your team for processing.
          </AlertTitle>
        </Alert>
      </Snackbar>
    </>
  )
}

export default ClientUploadBill
