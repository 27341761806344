import React from 'react'
import { Box, Button, Stack, Typography, ButtonProps, DialogContent } from '@mui/material'
import { ButtonPrimary } from '../../utils/styles'

interface Props extends ButtonProps {
  confirmMsg: string
  confirmText: string
  cancelText: string
  isLoading?: boolean
  handleDiscard?: () => void
  handleConfirm: () => Promise<void> | void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ConfirmModalContent: React.FC<Props> = ({
  confirmMsg,
  confirmText,
  cancelText,
  isLoading,
  handleDiscard,
  handleConfirm,
  setOpen,
  ...restProps
}) => {
  const handleClose = () => {
    setOpen(false)
    if (handleDiscard) handleDiscard()
  }

  return (
    <DialogContent
      sx={{
        paddingTop: 0,
      }}
    >
      <Typography
        align="center"
        fontSize="18px"
        color="rgba(0, 0, 0, 0.87)"
        fontWeight={325}
        mb={3}
      >
        {confirmMsg}
      </Typography>
      <Stack direction="row" display="flex" justifyContent="center">
        <Box mr={2}>
          <Button
            onClick={handleClose}
            sx={{
              width: '236px',
              height: '40px',
              border: '1px solid #2B388D',
            }}
          >
            <Typography color="#2B388D" fontWeight={400}>
              {cancelText}
            </Typography>
          </Button>
        </Box>
        <Box>
          <ButtonPrimary
            onClick={() => {
              handleConfirm()
            }}
            sx={{ width: '236px', height: '40px' }}
            {...restProps}
            disabled={isLoading}
          >
            <Typography fontWeight={400}>{confirmText}</Typography>
          </ButtonPrimary>
        </Box>
      </Stack>
    </DialogContent>
  )
}

export default ConfirmModalContent
