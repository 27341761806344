import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import LoadingIndicator from '../components/loading/LoadingIndicator'
import PrivateRoute from '../components/route/PrivateRoute'
import PublicRoute from '../components/route/PublicRoute'
import BaseLayout from '../containers/general/BaseLayout'

const Login = lazy(() => import('../containers/general/Login'))
const CreateAccount = lazy(() => import('../containers/general/CreateAccount'))
const ForgotPassword = lazy(() => import('../containers/general/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/general/ResetPassword'))
const ResetExpiredPassword = lazy(
  () => import('../containers/general/ResetPassword/ResetExpiredPassword')
)
const CreateAccountDownloadApp = lazy(
  () => import('../containers/general/CreateAccount/CreateAccountDownloadApp')
)
const LoginMobileView = lazy(() => import('../containers/general/Login/LoginMobileView'))
const userResetPasswordExpiry = '/user/reset-password-expiry'
const GenericRoutes = (
  <Route
    exact
    path={[
      '/login',
      '/create-account',
      '/forgot-password',
      '/reset-password',
      '/download-app',
      '/continue-download-app',
      userResetPasswordExpiry,
    ]}
  >
    <BaseLayout>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/create-account" component={CreateAccount} />
          <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
          <PublicRoute exact path="/reset-password" component={ResetPassword} />
          <PublicRoute exact path="/download-app" component={CreateAccountDownloadApp} />
          <PublicRoute exact path="/continue-download-app" component={LoginMobileView} />
        </Switch>
        <Switch>
          <PrivateRoute exact path={userResetPasswordExpiry} component={ResetExpiredPassword} />
        </Switch>
      </Suspense>
    </BaseLayout>
  </Route>
)

export default GenericRoutes
