import { IClient } from '../service/interface'

export const clientGuidProperty = 'clientGuid'
export const clientNameProperty = 'clientName'
export const clientCreatedOnProperty = 'clientCreatedOn'
export const clientClientRoleProperty = 'clientRole'
export const clientOnboardingCompleted = 'isOnboardingCompleted'

export const loadClientGuid = () => window.localStorage.getItem(clientGuidProperty) || ''

export const loadClientName = () => window.localStorage.getItem(clientNameProperty) || ''

export const saveClientGuid = (guid: string) =>
  window.localStorage.setItem(clientGuidProperty, guid)

export const saveClientName = (name: string) =>
  window.localStorage.setItem(clientNameProperty, name)

export const loadClientRole = () => window.localStorage.getItem(clientClientRoleProperty) || ''

export const saveClientRole = (role: string) =>
  window.localStorage.setItem(clientClientRoleProperty, role)

export const loadClientCreatedOn = () => window.localStorage.getItem(clientCreatedOnProperty) || ''

export const saveClientCreatedOn = (guid: string) =>
  window.localStorage.setItem(clientCreatedOnProperty, guid)

export const saveClientToStorage = (org: IClient) => {
  saveClientGuid(org.guid)
  saveClientName(org.name)
}

export const loadIsOnboardingCompleted = () =>
  window.localStorage.getItem(clientOnboardingCompleted) || '0'

export const saveIsOnboardingCompleted = (isCompleted: string) =>
  window.localStorage.setItem(clientOnboardingCompleted, isCompleted)
