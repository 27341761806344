import React, { useState } from 'react'
import { Box, Chip, Link, styled, SvgIcon } from '@mui/material'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { ReactComponent as ViewIcon } from '../../assets/images/icons/report_view_icon.svg'
import DescriptionIcon from '@mui/icons-material/Description'
import Previewer from './Previewer'
import { bgGray500 } from '../../utils/styles'

interface Props {
  file: string | File
  destroy: () => Promise<any>
  fileType?: string
  isOnboarding?: boolean
  isClientTemplate?: boolean
  isClient?: boolean
  isClientUser?: boolean
  createdOn?: string
  docGuid?: string
  clientGuid?: string
}

const StyledViewDoc = styled(Chip)(() => ({
  backgroundColor: 'transparent',
  fontWeight: '500',
  fontSize: 14,
  color: '#09101D',
  '& .MuiChip-icon ': {
    marginLeft: 0,
  },
}))

const DocPreviewer: React.FC<Props> = ({
  file,
  destroy,
  fileType,
  isOnboarding,
  isClientTemplate,
  isClient,
  isClientUser,
  createdOn,
  docGuid,
  clientGuid,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file)

  return (
    <>
      <Previewer
        file={file}
        fileUrl={fileUrl}
        fileType={fileType}
        expanded={expanded}
        attachmentIcon={DescriptionIcon}
        setExpanded={setExpanded}
        destroy={destroy}
        isOnboarding={isOnboarding}
        createdOn={createdOn}
        docGuid={docGuid}
        clientGuid={clientGuid}
        isClientTemplate={isClientTemplate}
        isClient={isClient}
        isClientUser={isClientUser}
      />
      {expanded && (
        <>
          <Box sx={{ background: bgGray500 }}></Box>
          <Box height="50px" overflow="auto" display="flex" justifyContent="center" mt={2}>
            <Link href={fileUrl} target="_blank" sx={{ textDecoration: 'none' }}>
              <StyledViewDoc
                icon={<SvgIcon component={ViewIcon} />}
                label="No file preview. Click to download."
              />
            </Link>
          </Box>
        </>
      )}
    </>
  )
}

export default DocPreviewer
