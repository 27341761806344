import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import EmptyTableIcon from '../../assets/images/icons/empty_table.svg'

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))

const StyledTypography = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 325,
  marginTop: 2,
}))

export const CustomNoRowsOverlay = (
  description: string,
  description_2?: string,
  imgDisplay?: string,
  qboConnection?: boolean
) => {
  return (
    <StyledBox>
      <img alt="empty_table" src={imgDisplay ? imgDisplay : EmptyTableIcon} />

      {qboConnection ? (
        <StyledTypography color="rgba(0, 0, 0, 0.60)">{description}</StyledTypography>
      ) : (
        <StyledTypography>{description}</StyledTypography>
      )}
      <StyledTypography>{description_2}</StyledTypography>
    </StyledBox>
  )
}
