import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'
import {
  createOnboardingDocs,
  deleteClientOnboardingDocs,
  listClientOnboardingDocs,
  retrieveClientOnboardingDocs,
  updateClientOnboardingDocs,
} from '../api'
import { IError, IDocsRequest, IListOnboardingDocsResponse, IOnboardingDocs } from '../interface'

export const OnboardingDocsQueryKeys = {
  GET_DOCS_LIST: 'GET_DOCS_LIST',
  GET_DOC_DATA: 'GET_DOC_DATA',
}

export const useListClientOnboardingDocs = (
  clientGuid: string,
  payload: IDocsRequest,
  options?: UseQueryOptions<IListOnboardingDocsResponse, unknown, IListOnboardingDocsResponse>
) =>
  useQuery(
    [OnboardingDocsQueryKeys.GET_DOCS_LIST, clientGuid, payload],
    () => listClientOnboardingDocs(clientGuid, payload),
    options
  )

export const useRetrieveClientOnboardingDocs = (
  clientGuid: string,
  documentGuid: string,
  payload: IDocsRequest,
  options?: UseQueryOptions<IOnboardingDocs, unknown, IOnboardingDocs>
) =>
  useQuery(
    [OnboardingDocsQueryKeys.GET_DOC_DATA, clientGuid, documentGuid, payload],
    () => retrieveClientOnboardingDocs(clientGuid, documentGuid, payload),
    options
  )

export const useCreateClientOnboardingDocs = (
  config?: UseMutationOptions<undefined, IError, { clientGuid: string; file: any }>
) => useMutation<undefined, IError, { clientGuid: string; file: any }>(createOnboardingDocs, config)

export const useUpdateClientOnboardingDocs = (
  config?: UseMutationOptions<
    IOnboardingDocs,
    IError,
    {
      clientGuid: string
      documentGuid: string
      payload: IDocsRequest
    }
  >
) =>
  useMutation<
    IOnboardingDocs,
    IError,
    { clientGuid: string; documentGuid: string; payload: IDocsRequest }
  >(updateClientOnboardingDocs, config)

export const useDeleteClientOnboardingDocs = (
  config?: UseMutationOptions<
    IOnboardingDocs,
    IError,
    {
      clientGuid: string
      documentGuid: string
    }
  >
) =>
  useMutation<IOnboardingDocs, IError, { clientGuid: string; documentGuid: string }>(
    deleteClientOnboardingDocs,
    config
  )
