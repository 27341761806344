import React, { useEffect, useState } from 'react'
import { use2FAActivate } from '../../../../service/hooks/auth'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import CustomDialog from '../../../../components/CustomDialog'
import { Box, Button, Typography } from '@mui/material'
import CustomPhoneInput from '../../../../components/CustomPhoneInput'
import { TwoFASetupStepProps } from './interface'
import ErrorAlert from '../../../../components/ErrorAlert'

const PhoneNumberStep: React.FC<TwoFASetupStepProps> = ({
  open,
  onClose,
  onBack,
  onNext,
  phone,
  setPhone,
}) => {
  const { mutateAsync: requestActivate, error, isLoading } = use2FAActivate()
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true)

  useEffect(() => {
    setIsPhoneNumberValid(true)
  }, [phone])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isValidPhoneNumber(phone)) {
      await requestActivate({
        phone,
        method: 'sms',
      })
      onNext()
    } else {
      setIsPhoneNumberValid(false)
    }
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      buttonCloseOnly
      title="Two Factor Authentication Setup"
      contentDividers
      formOnSubmit={(e) => handleSubmit(e)}
      actions={
        <>
          <Button variant="outlined" onClick={onBack}>
            Cancel
          </Button>
          <Button variant="contained" onClick={(event) => handleSubmit(event)} disabled={isLoading}>
            Next
          </Button>
        </>
      }
    >
      <Typography fontSize={14} fontWeight={350} mb={1}>
        Please input your phone number to verify
      </Typography>
      <PhoneInput
        placeholder="Enter phone number"
        defaultCountry="US"
        value={phone}
        onChange={setPhone}
        inputComponent={CustomPhoneInput}
      />
      <Box mt={1}>
        {!isPhoneNumberValid && <ErrorAlert error="Input valid phone number." />}
        {error && <ErrorAlert error={error} />}
      </Box>
    </CustomDialog>
  )
}

export default PhoneNumberStep
