import React, { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { EToastType, ToastContext } from '../../../../contexts/ToastContext'
import { AuthQueryKeys, use2FAActivate, use2FAConfirm } from '../../../../service/hooks/auth'
import { getRefreshToken, saveGeneralAuthToStorage } from '../../../../utils/auth'
import { INormalLoginResponse } from '../../../../service/interface'
import { Button, Stack, Typography } from '@mui/material'
import { TwoFASetupStepProps } from './interface'
import CustomDialog from '../../../../components/CustomDialog'
import CustomOTPInput from '../../../../components/CustomOTPInput'

const VerifyCodeStep: React.FC<TwoFASetupStepProps> = ({
  open,
  onClose,
  onBack,
  onNext,
  phone,
}) => {
  const queryClient = useQueryClient()
  const { showToast } = useContext(ToastContext)
  const { mutateAsync: request2FAConfirm, isError, isLoading } = use2FAConfirm({
    onSuccess: (data) => {
      showToast({
        type: EToastType.success,
        message: 'Successfully verified the phone number and added 2FA',
      })
      saveGeneralAuthToStorage(data as INormalLoginResponse)
      onNext()
    },
  })
  const [otp, setOtp] = useState('')
  const { mutateAsync: requestActivate, isLoading: isResendLoading } = use2FAActivate()

  const handleResend = async () => {
    await requestActivate({
      phone,
      method: 'sms',
    })
  }

  const handleConfirm = async (e: React.FormEvent) => {
    e.preventDefault()
    await request2FAConfirm({
      code: otp,
      method: 'sms',
      refresh: getRefreshToken(),
    })
    await queryClient.refetchQueries([AuthQueryKeys.GET_USER, 'me'])
  }

  return (
    <CustomDialog
      open={open}
      title="Two Factor Authentication Setup"
      onClose={onClose}
      buttonCloseOnly
      contentDividers
      formOnSubmit={handleConfirm}
      actions={
        <>
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button type="submit" variant="contained" onClick={handleConfirm} disabled={isLoading}>
            Confirm
          </Button>
        </>
      }
    >
      <Stack alignItems="center">
        <Typography mb={2} fontSize={16} fontWeight={350}>
          Please enter the code sent to your phone
        </Typography>
        <CustomOTPInput
          value={otp}
          onChange={setOtp}
          hasErrored={isError}
          onResend={handleResend}
          isResendLoading={isResendLoading}
        />
      </Stack>
    </CustomDialog>
  )
}

export default VerifyCodeStep
