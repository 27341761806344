import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Chip,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/system'
import { clientRoutesPath } from '../../../routes/ClientRoutes'
import { EBillAction, EWorkflowState } from '../../../service/enum'
import BillTable from '../../../components/table/client/BillTable'
import {
  ClientBillQueryKeys,
  useClientBillActionBulkApproveUpdate,
} from '../../../service/hooks/clientBill'
import { queryClient } from '../../../App'
import { EToastType, ToastContext } from '../../../contexts/ToastContext'
import ErrorAlert, { getErrorText } from '../../../components/ErrorAlert'
import { IRow } from '../../../components/table/client/BillTable/interface'
import {
  IBulkApproveBill,
  IClient,
  IDateRange,
  IEntity,
  IListBillSummaryResponse,
  UserType,
} from '../../../service/interface'
import { OrgBillQueryKeys, useOrgBillActionUpdate } from '../../../service/hooks/orgBill'
import { IBillingTab } from './interface'
import { listBillSummary } from '../../../service/api'
import { useClientApproveBillAndNotify } from '../../../utils/hooks'
import DateRangeFilter from '../../../components/DateFilter'
import { ReactComponent as DollarIcon } from '../../../assets/images/icons/dollar_icon.svg'
import ViewTotalModal from '../../../components/modals/ViewTotalModal'
import { BillParamsContext } from '../../../contexts/BillParamsContext'
import { GridRowId } from '@mui/x-data-grid'
import { CustomTabPanel, StyledContainedTab, StyledContainedTabs } from '../../../utils/tabStyles'

interface Props {
  type: UserType
  guid: string
  entities?: IEntity[]
  tabs: IBillingTab[]
  clients?: IClient[]
  clientGuid?: string
  selectedDateRange: IDateRange
  setSelectedDateRange?: React.Dispatch<React.SetStateAction<IDateRange>>
}

const StyledViewTotal = styled(Chip)(() => ({
  backgroundColor: 'transparent',
  fontSize: 14,
  fontWeight: 350,
  color: '#2B388D',
  '& .MuiChip-icon ': {
    position: 'absolute',
    right: 20,
    top: 22,
  },
  '& .MuiChip-label ': {
    paddingRight: 30,
  },
}))

const BillTabs: React.FC<Props> = ({
  type,
  entities,
  guid,
  tabs,
  clients,
  clientGuid,
  selectedDateRange,
  setSelectedDateRange,
}: Props) => {
  const { entityGuid } = useParams<{ entityGuid: string }>()
  const { pathname, search } = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const { showToast } = useContext(ToastContext)
  const { params, setSelectedClient, setSelectedEntity } = useContext(BillParamsContext)
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    approveBillAndNotify,
    error: clientActionApproveError,
    isError: isClientActionApproveError,
    isLoading: isNotifyLoading,
  } = useClientApproveBillAndNotify()
  const {
    mutateAsync: requestUpdateOrgBillAction,
    error: orgActionError,
    isError: isOrgActionError,
    isLoading: isUpdateLoading,
  } = useOrgBillActionUpdate()
  const {
    mutateAsync: requestUpdateClientBillBulkActionApprove,
    error: clientActionBulkApproveError,
    isError: isClientActionBulkApproveError,
    isLoading: isBillActionLoading,
  } = useClientBillActionBulkApproveUpdate()
  const [selectionModel, setSelectionModel] = React.useState<IRow[]>([])
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([])
  const [summaryData, setSummaryData] = useState<IListBillSummaryResponse | undefined>()

  const searchParams = new URLSearchParams(search)
  const activeTab = parseInt(searchParams.get('tab') || '0')

  const [viewTotal, setViewTotal] = useState<boolean>(false)

  const selectedEntity = useMemo(() => params.entities || 'all-entities', [params.entities])

  const selectedClient = useMemo(
    () => params.clients || (clientGuid ? clientGuid : 'all-clients'),
    [clientGuid, params.clients]
  )

  useEffect(() => {
    setSelectedEntity(entityGuid)
  }, [entityGuid, setSelectedEntity])

  useEffect(() => {
    async function fetchSummaries() {
      try {
        await listBillSummary(guid, {
          clients: selectedClient === 'all-clients' ? '' : selectedClient,
        }).then((response) => {
          setSummaryData(response)
        })
      } catch (e) {
        //
      }
    }
    fetchSummaries()
  }, [selectedClient, guid])

  const handleStateChange = (event: SelectChangeEvent) => {
    if (event.target.value) history.push(event.target.value as string)
  }

  const handleApproveClick = async (billGuid: string, guid: string, extBillId: string) => {
    if (type === 'client') {
      await approveBillAndNotify(guid, billGuid, extBillId)
    } else {
      await requestUpdateOrgBillAction({
        orgGuid: guid,
        billGuid: billGuid,
        action: EBillAction.approve,
        client_approval_required: true,
      })
      queryClient.invalidateQueries([OrgBillQueryKeys.GET_BILL_LIST])
      showToast({
        type: EToastType.success,
        message: 'Bill successfully approved',
      })
    }
  }

  const handleTabClick = async (event: React.SyntheticEvent, newValue: number) =>
    history.push({ search: `?tab=${newValue}` })

  const handleEntityChange = (event: SelectChangeEvent) => {
    setSelectedEntity(event.target.value)
    if (event.target.value) history.push(`${clientRoutesPath.bills}/${event.target.value}`)
  }

  const handleApproveSelected = async () => {
    const approveBillData: IBulkApproveBill[] = []

    selectionModel.map((value) => {
      approveBillData.push({
        bill: value.details.guid,
        action: EBillAction.approve,
      })
    })
    if (type === 'client') {
      await requestUpdateClientBillBulkActionApprove({
        clientGuid: guid,
        requests: approveBillData,
      })
        .then(() => {
          showToast({
            type: EToastType.success,
            message: 'Bills successfully approved',
          })
        })
        .catch((e) => {
          showToast({
            type: EToastType.error,
            header: 'Error has occurred',
            message: `Unable to approve bill: ${getErrorText(e)}`,
          })
        })

      await queryClient.invalidateQueries([ClientBillQueryKeys.GET_BILL_LIST])
    } else {
      const requestUpdateBillAction = async () => {
        const promises = approveBillData.map((data) =>
          requestUpdateOrgBillAction({
            orgGuid: guid,
            billGuid: data.bill,
            action: EBillAction.approve,
            client_approval_required: true,
          })
        )
        const settledPromises = await Promise.allSettled(promises)
        const results = settledPromises.filter((result) => result.status === 'fulfilled')
        const errors = settledPromises.filter((result) => result.status === 'rejected')

        if (results.length) {
          showToast({
            type: EToastType.success,
            message: 'Bills successfully approved',
          })
        }
        if (errors.length) {
          showToast({
            type: EToastType.error,
            header: 'Error has occurred',
            message: `Unable to approve bill.`,
          })
        }
        await queryClient.invalidateQueries([OrgBillQueryKeys.GET_BILL_LIST])
      }

      requestUpdateBillAction()
    }
  }

  const handleClearAll = () => {
    setSelectionModel([])
    setSelectedRows([])
  }

  const handleClientChange = async (event: SelectChangeEvent) => {
    setSelectedClient(event.target.value)
  }

  const handleViewTotal = () => {
    setViewTotal((prev) => !prev)
  }

  const setTabLabel = (tabLabel: string) => {
    switch (tabLabel) {
      case 'Pre-bill':
        const preBill = summaryData?.pre_bill || 0
        return <StyledContainedTab key={tabLabel} label={tabLabel + ' (' + preBill + ')'} />
      case 'Needs Approval - Client':
        const clientApproval = summaryData?.client_approval || 0
        return <StyledContainedTab key={tabLabel} label={tabLabel + ' (' + clientApproval + ')'} />
      case 'Needs Approval - Plumb':
        let billsCount = 0
        if (summaryData?.org_approval) billsCount = billsCount + summaryData?.org_approval
        if (summaryData?.verbal_approval) billsCount = billsCount + summaryData?.verbal_approval
        if (summaryData?.poa_approval) billsCount = billsCount + summaryData?.poa_approval
        return <StyledContainedTab key={tabLabel} label={tabLabel + ' (' + billsCount + ')'} />
      case 'Approved':
        const paymentPending = summaryData?.payment_pending || 0
        return <StyledContainedTab key={tabLabel} label={tabLabel + ' (' + paymentPending + ')'} />
      default:
        return <StyledContainedTab key={tabLabel} label={tabLabel} />
    }
  }

  return (
    <Box>
      {isClientActionApproveError && <ErrorAlert error={clientActionApproveError} />}
      {isClientActionBulkApproveError && <ErrorAlert error={clientActionBulkApproveError} />}
      {isOrgActionError && <ErrorAlert error={orgActionError} />}
      {viewTotal && (
        <ViewTotalModal
          open={viewTotal}
          title={
            <>
              <Typography fontWeight={350}>{tabs[activeTab].label}: View Total</Typography>
              <Typography color="#394A64">
                {tabs[activeTab].workflowState === EWorkflowState.client_approval
                  ? 'The amount below shows the total amount that needs client approval.'
                  : 'The amount below shows the total amount that needs plumb approval.'}
              </Typography>
            </>
          }
          selectedClient={selectedClient === 'all-clients' ? undefined : selectedClient}
          selectedDateRange={selectedDateRange}
          workflowState={tabs[activeTab].workflowState}
          onClose={handleViewTotal}
        />
      )}
      <Paper style={{ boxShadow: 'none' }}>
        {!isSmallViewPort ? (
          <>
            <Stack direction="row" mb={2}>
              <Box sx={{ flexGrow: 1.5 }}>
                <StyledContainedTabs
                  value={activeTab}
                  onChange={handleTabClick}
                  aria-label="client-tabs"
                  variant="scrollable"
                >
                  {tabs.map((tab) => {
                    if ((clients && summaryData) || type === 'bporg') {
                      return setTabLabel(tab.label)
                    } else {
                      return <StyledContainedTab label={tab.label} />
                    }
                  })}
                </StyledContainedTabs>
              </Box>
              {type === 'bporg' && setSelectedDateRange && (
                <Box>
                  <DateRangeFilter
                    hasMaxDate={true}
                    defaultRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                  />
                </Box>
              )}
              {type === 'bporg' && [2, 3].includes(activeTab) && (
                <Button variant="outlined" onClick={handleViewTotal} sx={{ marginLeft: 2 }}>
                  <StyledViewTotal
                    icon={<SvgIcon component={DollarIcon} />}
                    label="View Total"
                    color="primary"
                  />
                </Button>
              )}
              {type === 'bporg' && clients ? (
                <Box ml={1} display="flex" justifyContent="flex-end">
                  <Select
                    native
                    value={selectedClient}
                    onChange={handleClientChange}
                    sx={{ width: 250 }}
                  >
                    <option value="all-clients" key="all-clients">
                      All Clients
                    </option>
                    {clients.map((client) => (
                      <option value={client.guid} key={client.guid}>
                        {client.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              ) : entities ? (
                <Box sx={{ justifyContent: 'flex-end' }} ml={1}>
                  <Select
                    native
                    value={selectedEntity}
                    onChange={handleEntityChange}
                    sx={{ width: 250 }}
                  >
                    <option value="all-entities" key="all-entities">
                      All Entities
                    </option>
                    {entities?.map((entity) => (
                      <option value={entity.guid} key={entity.guid}>
                        {entity.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              ) : (
                <></>
              )}
            </Stack>
            {selectionModel.length > 0 && (
              <Stack
                direction="row"
                sx={{
                  backgroundColor: 'rgba(2, 136, 209, 0.12)',
                  borderRadius: 3,
                  justifyContent: 'center',
                }}
              >
                <Box display="flex" justifyContent="center" flexGrow={1}>
                  <Typography mt={1.5}>
                    {selectionModel.length}{' '}
                    {selectionModel.length <= 1 ? 'Bill selected' : 'Bills selected'}
                  </Typography>
                  <Button
                    type="submit"
                    onClick={handleApproveSelected}
                    disabled={isNotifyLoading || isUpdateLoading || isBillActionLoading}
                  >
                    <Typography
                      fontWeight={350}
                      color="#01579B"
                      sx={{ textDecoration: 'underline' }}
                    >
                      {selectionModel.length <= 1 ? 'Approve' : 'Approve All'}
                    </Typography>
                  </Button>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    onClick={handleClearAll}
                    disabled={isNotifyLoading || isUpdateLoading || isBillActionLoading}
                  >
                    <Typography
                      fontWeight={350}
                      color="#01579B"
                      sx={{ textDecoration: 'underline' }}
                    >
                      Clear All
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            )}
            {tabs.map((tab, index) => (
              <CustomTabPanel key={tab.label} value={activeTab} index={index}>
                {tab.label === 'Pre-bill' ? (
                  <BillTable
                    guid={guid}
                    type={type}
                    tabLabel={tab.label}
                    workflow_state={tab.workflowState}
                    clientApprovalAction={tab.clientApprovalAction}
                    excludedColumns={tab.excludedColumns}
                    isPreBill={true}
                    selectedDateFilterKey={tab.dateFilterKey}
                    selectedDateRange={selectedDateRange}
                  />
                ) : (
                  <BillTable
                    guid={guid}
                    type={type}
                    tabLabel={tab.label}
                    workflow_state={tab.workflowState}
                    clientApprovalAction={tab.clientApprovalAction}
                    excludedColumns={tab.excludedColumns}
                    checkboxSelection={
                      tab.label === 'Needs Approval' || tab.label === 'Needs Approval - Plumb'
                    }
                    setSelectionModel={
                      tab.label === 'Needs Approval' || tab.label === 'Needs Approval - Plumb'
                        ? setSelectionModel
                        : undefined
                    }
                    setSelectedRows={
                      tab.label === 'Needs Approval' || tab.label === 'Needs Approval - Plumb'
                        ? setSelectedRows
                        : undefined
                    }
                    selectedRows={
                      tab.label === 'Needs Approval' || tab.label === 'Needs Approval - Plumb'
                        ? selectedRows
                        : undefined
                    }
                    onApprove={
                      tab.label.includes('Needs Approval') ? handleApproveClick : undefined
                    }
                    selectedDateFilterKey={tab.dateFilterKey}
                    selectedDateRange={selectedDateRange}
                  />
                )}
              </CustomTabPanel>
            ))}
          </>
        ) : (
          <Select native value={pathname} onChange={handleStateChange}>
            {tabs.map((tab) => (
              <option value={tab.path} key={tab.path}>
                {tab.label}
              </option>
            ))}
          </Select>
        )}
      </Paper>
    </Box>
  )
}

export default BillTabs
