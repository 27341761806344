import React from 'react'
import { Chip } from '@mui/material'
import { EWorkflowState } from '../service/enum'

interface Props {
  status: EWorkflowState | undefined
}

export const statusMap = {
  pre_bill: {
    label: 'Pre-bill',
    color: '#1271AF',
    bgcolor: 'rgba(2, 136, 209, 0.12)',
  },
  client_approval: {
    label: 'Needs Client Approval',
    color: '#089171',
    bgcolor: '#CFFCF2',
  },
  verbal_approval: {
    label: 'Needs Verbal Approval',
    color: '#2B388D',
    bgcolor: 'rgba(43, 56, 141, 0.08)',
  },
  poa_approval: {
    label: 'Needs POA',
    color: '#1271AF',
    bgcolor: 'rgba(2, 136, 209, 0.12)',
  },
  org_approval: {
    label: 'Needs Plumb Approval',
    color: '#D77E28',
    bgcolor: 'rgba(215, 126, 40, 0.08)',
  },
  payment_pending: {
    label: 'Payment Pending',
    color: 'warning.main',
    bgcolor: 'rgba(215, 126, 40, 0.08)',
  },
  paid: {
    label: 'Paid',
    color: '#089171',
    bgcolor: '#CFFCF2',
  },
  rejected: {
    label: 'Rejected',
    color: 'error.main',
    bgcolor: 'rgba(255, 0, 0, 0.12)',
  },
}

const BillWorkflowStatusChip: React.FC<Props> = ({ status }) => {
  if (!status) return <Chip />

  const { label, color, bgcolor } = statusMap[status]
  return <Chip label={label} sx={{ color, bgcolor }} />
}

export default BillWorkflowStatusChip
